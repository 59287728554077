import axios from "axios";
import { host } from "./base";
import { defaultHeaders } from "./base";

export async function getPendingQueue() {
  const resp = await axios.get(
    `${host}/api/v1/nft/queue/get?queueType=pendingAny`,
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function getMintingQueue(qID) {
  const resp = await axios.get(
    `${host}/api/v1/nft/queue/get?queueType=minting&queueID=${qID}`,
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function getFusionQueue(qID) {
  const resp = await axios.get(
    `${host}/api/v1/nft/queue/get?queueType=fusion&queueID=${qID}`,
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function createMintingQueue() {
  const resp = await axios.post(
    `${host}/api/v1/nft/mint`,
    {}, // empty body
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function confirmFusion(queueID, imageURL, miniGameResult) {
  const resp = await axios.post(
    `${host}/api/v1/nft/fusion/${queueID}/confirm`,
    {
      imageURL: imageURL,
      miniGameResult: miniGameResult,
    },
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}


export async function createFusionQueue(nftID1, nftID2) {
  const resp = await axios.post(
    `${host}/api/v1/nft/fusion`,
    {
      nftID1: nftID1,
      nftID2: nftID2
    },
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function confirmMinting(queueID, imageURL, miniGameResult) {
  const resp = await axios.post(
    `${host}/api/v1/nft/minting/${queueID}/confirm`,
    {
      imageURL: imageURL,
      miniGameResult: miniGameResult,
    },
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function previewFusion(nftID1, nftID2) {
  const resp = await axios.get(
    `${host}/api/v1/nft/fusion/preview?weaponTokenID1=${nftID1}&weaponTokenID2=${nftID2}`,
    { headers: { ...defaultHeaders() } },
  ).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });

  return resp;
}

export async function getLeaderboards() {
  const resp = await axios.get(
    `${host}/api/v1/nft/leaderboards`,
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}