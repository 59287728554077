import { AuthContext } from "@ryanar/react-auth-provider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MintingQueueState, mintingQueueStateToString, Second, weaponRarityToString, weaponTypeToString, weaponRarityToColor, weaponTypeToColor } from "../../constants";
import { createMintingQueue, confirmMinting, getPendingQueue, getMintingQueue } from "../api/nft";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import MiniGameSlot from "./minigameslot";
import { useNavigate } from "react-router-dom";
import { ContractWrapper, GetContractWrapper } from "../../contractwrapper/contractWrapper";
import { useWeb3React } from "@web3-react/core";
import { Contract, ethers } from "ethers";
import axios from "axios";
import { isMetamaskErrorUserRejected, replaceURLToInfuraGateway } from "../../utilities";
import { ConnectWalletContext } from "./ConnectWalletContext";

const ForgingState = {
  miniGame: 1,
  selectImage: 2,
  submit: 3,
};
export function ForgingComponent(props) {
  const logTag = "[ForgingStep1Component]";

  const navigate = useNavigate();
  const contractWrapper = GetContractWrapper();

  const { authenticated } = React.useContext(AuthContext);
  const { account: walletAddress } = useWeb3React();
  const [pendingMintingQueue, setPendingMintingQueue] = useState(null);

  const [showForgingModal, setShowForgingModal] = useState(false);
  const [forgingState, setForgingState] = useState(ForgingState.selectImage);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [minigameResult, setMinigameResult] = useState(false);

  const [kkubAmountInEtherAsString, setKKUBAmountInEtherAsString] = useState("6");

  const refMinigame = useRef()
  const refIntervalID = useRef(null);

  const { refConnectWalletFunc } = useContext(ConnectWalletContext);

  const stopPendingMintingQueueInterval = () => {
    if (refIntervalID.current) {
      clearInterval(refIntervalID.current);
      refIntervalID.current = null;
    }
  };

  /**
   * 
   * @param {number} ms - duration each interval in milli seconds
   * @param {function(queue|null)} onGetQueue 
   * @returns 
   */
  const startPendingMintingQueueInterval = async (ms) => {
    const resp = await getPendingQueue();
    const {
      pendingMintingQueues: queues,
    } = resp.data.data;

    let queue = queues.length > 0 ? queues[0] : null;
    setPendingMintingQueue(queue);
    if (!queue) {
      return;
    }

    // stop before start new interval ...
    const queueID = queue.id;
    stopPendingMintingQueueInterval();

    refIntervalID.current = setInterval(async () => {
      const resp = await getMintingQueue(queueID);
      const {
        mintingQueues: queues,
      } = resp.data.data;
      queue = queues.length > 0 ? queues[0] : null;

      if (queue) {
        setPendingMintingQueue(queue);
        if (queue.state > MintingQueueState.readyForMinting) {
          setForgingState(ForgingState.submit);
        }
        // stop interval when we got success|failed state 
        if (queue.state >= MintingQueueState.success) {
          stopPendingMintingQueueInterval();
        }
      }
    }, ms);
  };

  const toggleModal = () => {
    // console.log(showForgingModal);
    if (showForgingModal) {
      // will close modal
      if (forgingState === ForgingState.miniGame) {
        refMinigame.current.stopGame();
      }
    } else {
      if (refMinigame.current) {
        refMinigame.current.resetGame();
      }
    }

    setShowForgingModal(!showForgingModal);
  };

  useEffect(() => {
    if (!authenticated) return;
    const { REACT_APP_WPMS_MINT_WEAPON_COST_IN_ETHER_MSF3: WPMS_MINT_WEAPON_COST_IN_ETHER_MSF3 } = process.env;
    if (WPMS_MINT_WEAPON_COST_IN_ETHER_MSF3) {
      const v = parseInt(WPMS_MINT_WEAPON_COST_IN_ETHER_MSF3);
      if (v % 1000 > 0) {
        setKKUBAmountInEtherAsString((v / 1000).toFixed(3).toString());
      } else {
        setKKUBAmountInEtherAsString(Math.floor(v / 1000).toString());
      }
    }


    startPendingMintingQueueInterval(5 * Second);
    return () => stopPendingMintingQueueInterval();
  }, [authenticated]);

  useEffect(() => {
    if (forgingState === ForgingState.miniGame) {
      if (refMinigame.current) {
        refMinigame.current.resetGame();
      }
    }
  }, [forgingState])

  const spinner = () =>
    <MDBSpinner className='mx-2' color='secondary' size="sm">
      <span className='visually-hidden'>Loading...</span>
    </MDBSpinner>;

  return <>
    {/* forging step 1 */}
    <div className="col-md-6 mb-4">
      <div className="card">
        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
          <img src="/wpms/images/material.jpg" className="img-fluid" />
          <a href="#!">
            <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
          </a>
        </div>
        <div className="card-body">
          <h5 className="card-title">Step 1: Buy Raw Materials</h5>
          <p className="card-text">
            Creating weapon requires raw materials. Each order costs you {kkubAmountInEtherAsString} KKUB, and you can only make 1 order at a
            time. After the payment is complete, you can proceed to step-2 to create your weapon.
          </p>
          {
            (() => {
              if (!authenticated) {
                return <a href="#!" className="btn btn-primary me-2" onClick={() => { refConnectWalletFunc.current(); }}>Connect Wallet</a>;
              }
              if (!pendingMintingQueue) {
                return <BeginMintingButton
                  walletAddress={walletAddress}
                  mintingCostInEtherAsString={kkubAmountInEtherAsString}
                  onDone={() => {
                    setForgingState(ForgingState.selectImage);
                    startPendingMintingQueueInterval(5 * Second);
                  }} />;
              }
              if (pendingMintingQueue.state >= MintingQueueState.success) {
                return <BeginMintingButton walletAddress={walletAddress}
                  mintingCostInEtherAsString={kkubAmountInEtherAsString}
                  onDone={() => {
                    setForgingState(ForgingState.selectImage);
                    startPendingMintingQueueInterval(5 * Second);
                  }} />;
              }
              if (pendingMintingQueue.state >= MintingQueueState.readyForMinting) {
                return <a href="#!" className="btn btn-primary me-2 disabled">Payment completed, continue to Step 2</a>;
              }
              return <a href="#!" className="btn btn-primary me-2 disabled">{spinner()}{mintingQueueStateToString(pendingMintingQueue.state)}</a>;
            })()
          }
          <a href="https://exchange.diamon.finance/#/swap?inputCurrency=KUB&outputCurrency=0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light mt-2">Convert KUB To KKUB</a>
        </div>
      </div>
    </div>

    {/* forging step 2 */}
    <div className="col-md-6 mb-4">
      <div className="card">
        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
          <img src="/wpms/images/forge.jpg" className="img-fluid" />
          <a href="#!">
            <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
          </a>
        </div>
        <div className="card-body">
          <h5 className="card-title">Step 2: Create Weapon</h5>
          <p className="card-text">
            Use materials in step-1 to forge gen#1 weapon. The process will allow you to choose 1 out of 3
            blueprints (uniquely generated by AI). Its type could be <span className="badge bg-success">Melee</span><span> </span>
            or <span className="badge bg-warning">Range</span> or <span className="badge bg-info">Magic Spell</span>. And
            the power is random between 100 to 120.
          </p>
          {
            (() => {
              if (!pendingMintingQueue) {
                return <button type="button" className="btn btn-primary disabled">Please complete step 1</button>;
              }

              const { state } = pendingMintingQueue;

              if (state < MintingQueueState.readyForMinting) {
                return <button type="button" className="btn btn-primary disabled">
                  {spinner()}
                  Processing step 1</button>;
              }
              if (state === MintingQueueState.readyForMinting) {
                return <ConfirmMintingButton onClick={() => toggleModal()} />;
              }
              if (state < MintingQueueState.success) {
                return <button type="button" className="btn btn-primary" onClick={() => toggleModal()}>
                  {spinner()}
                  {`Processing (${mintingQueueStateToString(state)})`}</button>
              }
              if (state == MintingQueueState.success) {
                return <button type="button" className="btn btn-primary" onClick={() => { navigate("/inventory") }}>View in inventory</button>
              }
              if (state == MintingQueueState.failed) {
                return <button type="button" className="btn btn-primary warn" onClick={() => { navigate("/inventory") }}>View in inventory</button>
              }
            })()
          }

          <MDBModal tabIndex='-1' show={showForgingModal} setShow={setShowForgingModal}>
            <MDBModalDialog className='modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered'>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Forging Gen#1 Weapon</MDBModalTitle>
                  <MDBBtn
                    type='button'
                    className='btn-close'
                    style={{ display: !minigameResult ? 'flex' : 'none' }}
                    color='none'
                    onClick={toggleModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  {
                    (() => {
                      if (!pendingMintingQueue) return null;

                      if (forgingState === ForgingState.selectImage && pendingMintingQueue.mintingData) {
                        return (
                          <SelectImage
                            mintingData={pendingMintingQueue.mintingData}
                            onSelectImage={(imageURL) => {
                              setSelectedImageURL(imageURL);
                            }} />
                        );
                      } else if (forgingState === ForgingState.miniGame) {
                        return (
                          <MiniGameSlot ref={refMinigame} image={selectedImageURL} weaponName={pendingMintingQueue.mintingData.name} onDone={(result) => {
                            if (result) {
                              if (refMinigame.current) {
                                refMinigame.current.stopGame();
                              }
                              setMinigameResult(result);
                              setTimeout(function () {
                                (async () => {
                                  const confirmResp = await confirmMinting(pendingMintingQueue.id, selectedImageURL, 0);
                                  if (confirmResp.data) {
                                    if (!confirmResp.data.success) {
                                      alert("Cannot confirm - WeaponMastery Server might has too many requests, please try again later");
                                      return;
                                    }
                                  }
                                  setForgingState(ForgingState.submit);
                                  if (refMinigame.current) {
                                    refMinigame.current.stopGame();
                                  }
                                  startPendingMintingQueueInterval(5 * Second);
                                  setMinigameResult(false);
                                })();
                              }, 2000);
                            }
                          }} />
                        );
                      } else if (forgingState === ForgingState.submit) {
                        return <MintingProgress queue={pendingMintingQueue} />
                      }
                      return null;
                    })()
                  }
                </MDBModalBody >
                <MDBModalFooter>
                  {
                    (() => {
                      if (forgingState === ForgingState.selectImage && selectedImageURL.length > 0) {
                        return <MDBBtn type='button' color='secondary' onClick={() => {
                          setForgingState(ForgingState.miniGame);
                        }}>
                          Continue
                        </MDBBtn>;
                      }
                      return null;
                    })()
                  }
                  {
                    (forgingState === ForgingState.miniGame && !minigameResult) ?
                      <MDBBtn type='button' color='secondary' onClick={() => {
                        setForgingState(ForgingState.selectImage);
                        if (refMinigame.current) {
                          refMinigame.current.stopGame();
                        }
                        setSelectedImageURL("");
                      }}>
                        Back
                      </MDBBtn> : <></>
                  }
                  {
                    (forgingState === ForgingState.miniGame && minigameResult) ?
                      <></> : <MDBBtn type='button' color='primary' onClick={toggleModal}>
                        Close
                      </MDBBtn>
                  }
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </div>
      </div>
    </div>
  </>
}

function BeginMintingButton(props) {
  const { walletAddress, mintingCostInEtherAsString, onDone } = props;
  const [isApproving, setIsApproving] = useState(false);

  return <a href="#!" className={`btn btn-primary me-2 ${isApproving ? "disabled" : ""}`} onClick={async () => {
    if (isApproving) return;

    const contractWrapper = GetContractWrapper();
    const minTokenAmountForMinting = ethers.utils.parseEther(mintingCostInEtherAsString);

    // check sufficient balance?
    const balance = await contractWrapper.getKKUBBalance(walletAddress);
    if (ethers.BigNumber.from(balance).lt(minTokenAmountForMinting)) {
      alert("Token not enough");
      return;
    }

    // check sufficient approval? and then ask for approval if requires
    let retryApprovalCounter = 0;
    let approvalEnough = false;

    const allowance = await contractWrapper.getKKUBTokenAllowanceForMastery(walletAddress);
    const minAllowanceInWei = ethers.BigNumber.from(minTokenAmountForMinting);
    if (allowance.gte(minAllowanceInWei)) {
      approvalEnough = true;
    }

    if (!approvalEnough) {
      try {
        setIsApproving(true);
        await contractWrapper.approveKKUBTokenToMastery(minAllowanceInWei);
      } catch (ex) {
        if (isMetamaskErrorUserRejected(ex)) {
          alert("user cancel");
          return;
        }
      } finally {
        setIsApproving(false);
      }
    }

    const resp = await createMintingQueue();
    const { queueID } = resp.data.data;
    if (!queueID) {
      alert("Cannot process forging - WeaponMastery Server might has too many requests, please try again later");
      return;
    }

    onDone();
  }}>{isApproving ? "Approving..." : `Buy Material (${mintingCostInEtherAsString} KKUB)`}</a>;
}

function ConfirmMintingButton(props) {
  const { onClick } = props;
  return (<button
    type="button" className="btn btn-primary"
    onClick={onClick}>
    Start forging gen#1 weapon
  </button>);
}

function GetSelectBorder(selectedImageURL, imageURL) {
  var style = ""
  if (selectedImageURL === imageURL) {
    style = { backgroundColor: "#39c0ed", borderStyle: "solid", borderColor: "#39c0ed", borderWidth: "5px" }
  } else {
    style = { backgroundColor: "#424242" }
  }
  return style
}

function SelectImage(props) {
  const { mintingData, onSelectImage } = props;
  const [selectedImageURL, setSelectedImageURL] = useState('');

  const { generatedImageURLs: imageURLs } = mintingData;

  const handleImageClick = (imageURL) => {
    onSelectImage(imageURL);
  };

  const raritySpan = weaponRarityToString(mintingData.rarity) === "Common" ? null : <span style={{ color: weaponRarityToColor(mintingData.rarity) }}>{weaponRarityToString(mintingData.rarity)}</span>
  return (
    <>
      <MDBRow className='text-center'>
        <MDBCol lg={10} className='d-grid gap-2 col-md-8 my-2 mx-auto'>

          <p>A new {weaponRarityToString(mintingData.rarity)} <span style={{ color: weaponTypeToColor(mintingData.type) }}>{weaponTypeToString(mintingData.type)}</span> weapon has been discovered!</p>

          <h3>Please select a blueprint for "<span style={{ color: weaponRarityToColor(mintingData.rarity) }}>{mintingData.name}</span>"</h3>

          <p>{mintingData.description}</p>

        </MDBCol>
      </MDBRow>

      <hr className="my-4" />

      <MDBRow>
        {imageURLs.map((imageURL, index) => (
          <MDBCol md='4' key={imageURL}>
            <MDBCard onClick={() => {
              setSelectedImageURL(imageURL)
              handleImageClick(imageURL);
            }} style={GetSelectBorder(selectedImageURL, imageURL)}>
              <MDBCardImage src={imageURL} />
              <MDBCardBody>
                <MDBCardTitle style={{ color: selectedImageURL === imageURL ? "#000" : "white" }}>{`Blueprint #${index + 1}`}</MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
        {/* <MDBCol md='12'>
          <h3>Selected Image:</h3>
          <img src={selectedImage} alt='' style={{ maxWidth: '100%' }} />
        </MDBCol> */}
      </MDBRow >
    </>
  );
}

function MintingProgress(props) {
  const { queue } = props;
  const [imageURL, setImageURL] = useState("");
  useEffect(() => {
    if (!queue.mintingData) return;
    if (!queue.mintingData.tokenID) return;
    (async () => {
      const contractWrapper = GetContractWrapper();
      const tokenURI = await contractWrapper.getWeaponTokenURI(queue.mintingData.tokenID);
      const resp = await axios.get(tokenURI);
      setImageURL(replaceURLToInfuraGateway(resp.data.image));
    })();
  }, [queue]);

  return <MDBRow className='text-center'> {
    (() => {
      if (queue.state < MintingQueueState.success) {
        return (
          <MDBCol lg={10}>
            <h5>Creating weapon... (Progress: {mintingQueueStateToString(queue.state)})</h5>
            The process is working in background. You can close this window and check your inventory later.
          </MDBCol>
        );
      } else if (queue.state === MintingQueueState.success && imageURL.length > 0) {
        return (
          <div className='d-grid gap-2 col-md-4 my-2 mx-auto'>
            <h5>You've received a new weapon!</h5>
            <div className="bg-image hover-overlay ripple shadow-1-strong rounded" data-mdb-ripple-color="light">
              <img src={imageURL} className="w-100" alt="Louvre" />
              <a href="#!">
                <div className="mask" style={{ "backgroundColor": "hsla(0, 0%, 98%, 0.2)" }}></div>
              </a>
            </div>
          </div>
        );
      } else if (queue.state === MintingQueueState.failed) {
        return (
          <MDBCol lg={10}>
            <h5>Failed to create Weapon.</h5>
          </MDBCol>
        );
      }
    })()}
  </MDBRow>;
}