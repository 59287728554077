import { Button } from "bootstrap";
import { MDBBtn, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle } from "mdb-react-ui-kit";
import React, { createRef, useEffect, useState } from "react";
import "./minigameslot.styles.css";

const initializedRows = () => {
  return [
    {
      freezed: false,
      name: 'middle',
      initValue: 0,
      value: 0,
      index: 0,
      endValue: 4,
      speed: 250,
      isRunning: false,
      key: Math.random(),
    }
  ];
};

export default class MiniGameSlot extends React.Component {

  constructor() {
    super();

    this.game = createRef()
    this.middleRowValue = createRef();
    this.refMiddleRow = createRef();

    this.state = {
      rows: initializedRows(),
      activeRowIndex: 0,
      wasHit: false,
      result: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.setEndValue = this.setEndValue.bind(this);
    this.setRotatingValue = this.setRotatingValue.bind(this);
    this.cancelInterval = this.cancelInterval.bind(this);
    this.resetGame = this.resetGame.bind(this);
    this.determinePrize = this.determinePrizeAndDone.bind(this);
  }

  componentWillMount() {
    this.resetGame();
  }

  handleClick() {
    var index = this.state.activeRowIndex;
    this.cancelInterval(index);
    this.setEndValue(index, this.state.rows[index].value);
    this.determinePrizeAndDone();
    this.setState({
      wasHit: true,
    });
  }

  determinePrizeAndDone() {
    const v = this.middleRowValue.current;
    let match = false;
    if (v === 2) {
      // console.log("matched - middleRowValue:", v);
      match = true;
    } else {
      // console.log("not matched - middleRowValue:", v);
      match = false;
    }

    this.setState({
      result: match,
    });

    setTimeout(() => {
      if (this.props.onDone) this.props.onDone(match);
    }, this.state.rows[0].speed);
  }

  resetGame() {
    // console.log("RESET.....");
    let rows = initializedRows();
    rows = this.state.rows.map(function (row) {
      row.key = Math.random();
      if (!row.freezed) {
        row.isRunning = true;
      }
      return row;
    });
    this.setState({ rows: rows, activeRowIndex: 0, wasHit: false }, () => {
      if (this.refMiddleRow.current) {
        this.refMiddleRow.current.reset();
      }
    });
  }

  stopGame() {
    var index = this.state.activeRowIndex;
    this.cancelInterval(index);
    if (this.refMiddleRow.current) {
      this.refMiddleRow.current.stop();
    }
    // console.log("STOP...");
  }

  setRotatingValue(index, value) {
    var rows = this.state.rows;
    var row = rows[index];
    row.value = value;
    rows[index] = row;
    this.setState({ rows: rows });
    if (index === 0) {
      this.middleRowValue.current = value;
    }
    // console.log("[MinigameSlot::setRotatingValue] set middleRowValue:", this.middleRowValue.current);
  }

  setEndValue(index, value) {
    var rows = this.state.rows;
    var row = rows[index];
    row.endValue = value;
    rows[index] = row;
    this.setState({ rows: rows });
  }

  cancelInterval(index) {
    var rows = this.state.rows;
    var row = rows[index];
    // console.log("index:", index);
    row.isRunning = false;
    rows[index] = row;
    this.setState({ rows: rows });
  }

  render() {
    var rows = this.state.rows.map(function (row) {
      return (
        <Row
          key={row.key}
          name={row.name}
          index={row.index}
          data={this.state}
          image={this.props.image}
          setRotatingValue={this.setRotatingValue}
          isRunning={row.isRunning}
          speed={row.speed}
          direction={row.direction}
          freezed={row.freezed}
          initValue={row.initValue}
          ref={this.refMiddleRow}
        />
      )
    }, this);

    return (
      <>
        <MDBRow className='text-center'>
          <MDBCol lg={10} className='d-grid gap-2 col-md-8 my-2 mx-auto'>

            <h3>Press the “HIT NOW” button to lock your Weapon.</h3>

            <p>Hit your weapon at the right moment to make the blueprint fit the whole area. You can try as many times as you want.</p>

            <hr className="my-3" />

          </MDBCol>
        </MDBRow>

        <div ref={this.game}>
          <div className="rows">
            <div className="d-grid gap-2 col-md-5 mx-auto">
              <MDBCol >
                <MDBCard>
                  <MDBCardBody>
                    <MDBCardTitle><div className="my-0">{rows}</div></MDBCardTitle>
                    <Results shown={this.state.wasHit} result={this.state.result} />
                    {
                      !this.state.wasHit ?
                        <MDBRow>
                          <MDBCol>
                            <div className="d-grid gap-2 col-12 my-0 mx-auto">
                              <MDBBtn type='button' color='primary' onClick={() => { this.handleClick() }}>
                                Hit "{this.props.weaponName}" Now
                              </MDBBtn>
                            </div>
                          </MDBCol>
                        </MDBRow> :
                        this.state.result ?
                          <>
                          </> :
                          <>
                            <MDBRow className='text-center'>
                              <MDBBtn
                                type='button'
                                onClick={() => {
                                  this.resetGame();
                                }}
                              >Try Again</MDBBtn>
                            </MDBRow>
                          </>
                    }
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </div>

          </div>
        </div>
      </>
    )
  }
}

class Row extends React.Component {
  constructor(props) {
    super();
    this.refHasClicked = createRef(false);
    this.state = {
      initValue: props.initValue,
      value: props.initValue,
    };
    this.refInterval = createRef();
    this.counterIntervalFunction = this.counterIntervalFunction.bind(this);
    this.clearCounterInterval = this.clearCounterInterval.bind(this);
  }

  componentWillMount() {
    this.reset();
  }

  reset() {
    if (!this.props.freezed) {
      this.clearCounterInterval();
      this.refHasClicked.current = false;

      this.refInterval.current = setInterval(this.counterIntervalFunction, this.props.speed);
      this.props.setRotatingValue(this.props.index, this.state.initValue - 1);
    }
    // console.log("[Row::reset]");
  }

  stop() {
    this.clearCounterInterval();
    // console.log("[Row::stop]");
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isRunning && this.state.interval) {
      this.clearCounterInterval();
    }
  }

  counterIntervalFunction() {
    if (this.props.isRunning) {
      var value = this.state.value > 0 ? this.state.value - 1 : 4; // 2 -> 1 -> 0
      var rotatingToValue = value > 0 ? value - 1 : 4; // 2 -> 1 -> 0
      this.setState({ value: value });
      this.props.setRotatingValue(this.props.index, rotatingToValue);
    } else {
      this.clearCounterInterval();
    }
  }

  clearCounterInterval() {
    if (this.refInterval.current) {
      clearInterval(this.refInterval.current);
      this.refInterval.current = null;
    }
  }

  render() {
    let activeRowIndex = this.props.data.activeRowIndex;
    let activeClass = this.props.index === activeRowIndex ? 'active' : '';
    let columnsClassList = 'columns columns-' + this.props.name;
    let wrapperClassList = activeClass;
    let style = {
      //backgroundImage: `url(/wpms/images/minigame-slot-${this.props.name}.png)`,
      backgroundImage: `url(${this.props.image})`
    };

    if (this.props.freezed) {
      style = {
        ...style,
        backgroundPosition: "0vw"
      };
    } else {
      let animation = 'transition-' + this.state.value;
      style = {
        ...style,
        animationName: animation,
        animationDuration: this.props.speed + 'ms',
      };
    }

    return (
      <div className={wrapperClassList} onClick={() => {
        if (this.refHasClicked.current) return;
        this.refHasClicked.current = true;
        if (this.props.handleClick) this.props.handleClick();
      }}>
        <div className={columnsClassList} style={style} />
      </div>
    )
  }
}

class Results extends React.Component {
  constructor() {
    super();
    this.state = {
      messages: [
        'Complete',
        'Miss'
      ]
    }
  }

  render() {
    var shown = this.props.shown ? 'shown' : '';
    var classList = 'results ' + shown;
    var message = ""

    if (this.props.result) {
      message = this.state.messages[0]
    } else {
      message = this.state.messages[1]
    }
    return (
      <div className={classList}>
        {message}
      </div>
    )
  }
}