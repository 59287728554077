// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import { AuthProvider } from "@ryanar/react-auth-provider";
import { Web3ReactProvider } from "@web3-react/core";
import { useRef, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CardTemplate from "./CardTemplate";
import Home from "./wpms/Home";
import Inventory from "./wpms/Inventory";
import MiniGameTest from "./wpms/MiniGameTest";
import { ConnectWalletContext } from "./wpms/components/ConnectWalletContext";
import { Navbar } from "./wpms/components/navbar";
import getLibrary from "./wpms/web3Provider";

function Footer(props) {
  return (
    <>
      <footer className="bg-dark text-center text-white">
        <div className="container p-4 pb-0">
          <section className="">
            <p className="d-flex justify-content-center align-items-center">
              <span className="me-1">Connect with us. Follow our official channel on </span>
              <a className="btn btn-outline-light btn-floating mx-2 " href="https://www.facebook.com/WeaponMasteryNFT" role="button" target="_blank"><i
                className="fab fa-facebook-f"></i></a>
              <a className="btn btn-outline-light btn-floating" href="https://twitter.com/WeaponMasteryTH" role="button" target="_blank"><i
                className="fab fa-twitter"></i></a>
            </p>
          </section>
        </div>
        <div className="text-center p-3" style={{ backgroundColor: `rgba(0, 0, 0, 0.2)` }}>
          © 2023 Copyright:
          <a className="text-white" href="https://weaponmastery.com/"> WeaponMastery.com</a>
        </div>
      </footer>
    </>
  );
}

function App(props) {
  const [hasAuthen, setHasAuthen] = useState(false);
  const refConnectWalletFunc = useRef(null);

  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <AuthProvider defaultAuthenticated={false}>
            <ConnectWalletContext.Provider value={{ refConnectWalletFunc }}>
              <Routes>
                <Route exact path="/" element={
                  <>
                    <header><Navbar hasAuthenTrigger={() => setHasAuthen(true)} /></header>
                    <Home />
                    <Footer />
                  </>
                }></Route>
                <Route exact path="/inventory" element={
                  <>
                    <header><Navbar hasAuthenTrigger={() => setHasAuthen(true)} /></header>
                    <Inventory />
                    <Footer />
                  </>
                }></Route>
                <Route exact path="/cardtemplate" element={<CardTemplate />}></Route>
                <Route exact path="/minigametest" element={<MiniGameTest />}></Route>
              </Routes>
            </ConnectWalletContext.Provider>
          </AuthProvider>
        </Router>
      </Web3ReactProvider>

      {/* <>
        <footer className="bg-dark text-center text-white">
          <div className="container p-4 pb-0">
            <section className="">
              <p className="d-flex justify-content-center align-items-center">
                <span className="me-1">Connect with us. Follow our official channel on </span>
                <a className="btn btn-outline-light btn-floating mx-2 " href="https://www.facebook.com/WeaponMasteryNFT" role="button" target="_blank"><i
                  className="fab fa-facebook-f"></i></a>
                <a className="btn btn-outline-light btn-floating" href="https://twitter.com/WeaponMasteryTH" role="button" target="_blank"><i
                  className="fab fa-twitter"></i></a>
              </p>
            </section>
          </div>
          <div className="text-center p-3" style={{ backgroundColor: `rgba(0, 0, 0, 0.2)` }}>
            © 2023 Copyright:
            <a className="text-white" href="https://weaponmastery.com/"> WeaponMastery.com</a>
          </div>
        </footer>
      </> */}
      <script type="text/javascript" src="/wpms/js/mdb.min.js"></script>
      <script type="text/javascript"></script>
    </>
  );
}

export default App;