export const goalComponent = () => (
  <div className="card mb-4">
    <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
      <img src="/wpms/images/goal.jpg" className="img-fluid" />
      <a href="#!">
        <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
      </a>
    </div>
    <div className="card-body">
      <h5 className="card-title">Goal</h5>
      <p className="card-text">
        The goal of this project is to study and research the real-time generative process of NFT. Every
        weapon is created to be unique, and can be combined together for endless possibility. By using the
        large language model AI, we extend the imagination and capability of what human-made game can do. A
        future of NFT and gaming industry is here.
      </p>
      <a href="#combine" className="btn btn-primary">Combine</a>
    </div>
  </div>
);