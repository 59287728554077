import { AuthContext } from "@ryanar/react-auth-provider";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ContractWrapper, GetContractWrapper } from "../contractwrapper/contractWrapper";
import { BigImageIntro } from "./components/bigImageIntro";
import { replaceURLToInfuraGateway } from "../utilities";

function Inventory(props) {
  const { authenticated } = React.useContext(AuthContext);
  const { account } = useWeb3React();
  const contractWrapper = GetContractWrapper();
  const [weapons, setWeapons] = useState([]);
  const [rarityCounter_, setRarityCounter] = useState(new Map());
  const [requireReload, setRequireReload] = useState(false);

  useEffect(() => {
    if (authenticated) {
      reload();
      setRequireReload(false);
    }
  }, [authenticated, requireReload]);

  const reload = async () => {
    const tokenIDs = await contractWrapper.getAllWeaponTokens(account);
    const weapons = [];
    const rarityCounter_ = new Map();

    for (const id of tokenIDs) {
      const uri = await contractWrapper.getWeaponTokenURI(id);
      const resp = await axios.get(uri);
      const { data } = resp;
      let rarity = "";
      for (const attr of data.attributes) {
        if (attr.trait_type === "Rarity" || attr.trait_type === "Raritry") {
          rarity = attr.value;
        }
      }

      if (rarity.length > 0) {
        const c = rarityCounter_.has(rarity) ? rarityCounter_.get(rarity) : 0;
        rarityCounter_.set(rarity, c + 1);
      }

      const weapon = {
        tokenID: id,
        rarity,
        ...data,
      };

      weapons.push(weapon);
    }
    setWeapons(weapons);
    setRarityCounter(rarityCounter_);
  };

  const numberOfWeaponsByRarity = (rarity) => rarityCounter_.has(rarity) ? rarityCounter_.get(rarity) : 0;

  return (<>
    <BigImageIntro />

    <div className="container my-5">

      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.2/css/all.css" />
      {/*} Google Fonts Roboto */}
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" />
      {/*} Dark MDB theme */}
      <link rel="stylesheet" href="/wpms/css/mdb.dark.min.css" />


      <section id="inventory">

        <hr className="my-5" />
        <div className="text-center">
          <h2><strong>Your Weapon Inventory</strong></h2>
          <p>You have {weapons.length} weapons in total. Common: {numberOfWeaponsByRarity("Common")}, Great: {numberOfWeaponsByRarity("Great")}, Rare: {numberOfWeaponsByRarity("Rare")}, Epic: {numberOfWeaponsByRarity("Epic")}, Legendary: {numberOfWeaponsByRarity("Legendary")}, Master: {numberOfWeaponsByRarity("Master")}</p>
        </div>
        <hr className="my-5" />

        <div className="row">
          {
            weapons.map((weapon) => (
              <div key={weapon.tokenID} className="col-lg-3 col-md-6 mb-4">
                <div className="bg-image hover-overlay ripple shadow-1-strong rounded" data-mdb-ripple-color="light">
                  <img src={replaceURLToInfuraGateway(weapon.image)} className="w-100" alt="Louvre" />
                  <a href="#!">
                    <div className="mask" style={{ "backgroundColor": "hsla(0, 0%, 98%, 0.2)" }}></div>
                  </a>
                </div>
              </div>
            ))
          }
        </div>

      </section>
    </div >
  </>);
}

export default Inventory;