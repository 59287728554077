import { capitalize } from "../../utilities";

export function OverviewComponent(props) {
  return (
    <>
      <div className="card">
        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
          <img src="/wpms/images/overview.jpg" className="img-fluid" />
          <a href="#!">
            <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
          </a>
        </div>
        <div className="card-body">
          <h5 className="card-title">Overview</h5>
          <p className="card-text">
            Weapon Mastery uses AI to create unique weapon-NFTs and combine them together. Make the most powerful
            weapons of your own and use them to mine WCOIN from the dungeon. There are 5 weapon types which are
            <span> </span><span className="badge bg-success">Melee</span> <span className="badge bg-warning">Range</span> <span
              className="badge bg-info">Magic Spell</span> <span className="badge bg-success">Magic Melee</span> <span
                className="badge bg-warning">Magic Range</span>, and 6 weapon rarities. The more power the weapon has,
            the more WCOIN you can get.
          </p>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr className="table-dark">
                  <th scope="col">Rarity</th>
                  <th scope="col">Starting Power</th>
                  <th scope="col">Combine Power Bonus</th>
                  <th scope="col">Combine Cost</th>
                </tr>
              </thead>
              <tbody>
                {props.weaponRarityTable.map((e, idx) =>
                  <tr className="table-light" key={idx}>
                    <th scope="row">
                      <button type="button" className={`btn btn-${e.style} btn-sm btn-rounded`}>
                        {capitalize(e.rarity)}
                      </button>
                    </th>
                    <td>{e.startingPower}</td>
                    <td>{e.combinePowerBonus}</td>
                    <td>{`${e.combineCost} WCOIN`}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <a href="#create" className="btn btn-primary">Create gen#1 weapon now!</a>
        </div>
      </div>
    </>
  );
}