export const introComponent = () => (
  <>
    <hr className="my-5" />
    <div className="text-center">
      <h2><strong>Weapon Mastery</strong></h2>
      <p>AI-Generated Mining Card Game - Over 1,000,000,000 possible weapons to collect and combine.</p>
      <p className="small text-muted text-uppercase mb-2">
      </p>
    </div>
    <hr className="my-5" />
  </>
);