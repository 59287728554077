import { AuthContext } from "@ryanar/react-auth-provider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { elementalTypeToString, FusionQueueState, fusionQueueStateToString, MintingQueueState, mintingQueueStateToString, Second, weaponRarityToColor, weaponRarityToString, weaponTypeToString, weaponTypeToColor } from "../../constants";
import { createMintingQueue, confirmMinting, getPendingQueue, getMintingQueue, getFusionQueue, createFusionQueue, previewFusion, confirmFusion } from "../api/nft";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import MiniGameSlot from "./minigameslot";
import { useNavigate } from "react-router-dom";
import { ContractWrapper, GetContractWrapper } from "../../contractwrapper/contractWrapper";
import { useWeb3React } from "@web3-react/core";
import { Contract, ethers } from "ethers";
import axios from "axios";
import { isMetamaskErrorUserRejected, replaceURLToInfuraGateway } from "../../utilities";
import { ConnectWalletContext } from "./ConnectWalletContext";

const CombineState = {
  selectWeapon: 1,
  miniGame: 2,
  selectImage: 3,
  submit: 4,
};

export function CombineWeaponComponent(props) {
  const logTag = "[CombineWeaponComponent]";

  const navigate = useNavigate();
  const contractWrapper = GetContractWrapper();
  const { authenticated } = React.useContext(AuthContext);
  const { account: walletAddress } = useWeb3React();
  const [pendingFusionQueue, setPendingFusionQueue] = useState(null);

  const [showWeaponSelectionModal, setShowWeaponSelectionModal] = useState(false);
  const closeWeaponSelectionModalWithConfirm = useRef(false);

  const [showCombineButton, setShowCombineButton] = useState(false);
  const [combineCost, setCombineCost] = useState("");
  const [showCombineModal, setShowCombineModal] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [minigameResult, setMinigameResult] = useState(false);

  const [combineState, setCombineState] = useState(CombineState.selectImage);
  const [weaponSelectionPair, setWeaponSelectionPair] = useState([]);

  const [isApproving, setIsApproving] = useState(false);

  const refMinigame = useRef()
  const refIntervalID = useRef(null);

  const { refConnectWalletFunc } = useContext(ConnectWalletContext);

  const stopPendingFusionQueueInterval = () => {
    if (refIntervalID.current) {
      clearInterval(refIntervalID.current);
      refIntervalID.current = null;
    }
  };
  /**
   * 
   * @param {number} ms - duration each interval in milli seconds
   * @param {function(queue|null)} onGetQueue 
   * @returns 
   */
  const startPendingFusionQueueInterval = async (ms) => {
    const resp = await getPendingQueue();
    const {
      pendingFusionQueues: queues,
    } = resp.data.data;

    let queue = queues.length > 0 ? queues[0] : null;
    setPendingFusionQueue(queue);
    if (!queue) {
      return;
    }

    // stop before start new interval ...
    const queueID = queue.id;
    stopPendingFusionQueueInterval();

    refIntervalID.current = setInterval(async () => {
      const resp = await getFusionQueue(queueID);
      const {
        fusionQueues: queues,
      } = resp.data.data;
      queue = queues.length > 0 ? queues[0] : null;

      if (queue) {
        setPendingFusionQueue(queue);
        if (queue.state > FusionQueueState.readyForFusion) {
          setCombineState(CombineState.submit);
        }
        // stop interval when we got success|failed state 
        if (queue.state >= FusionQueueState.success) {
          stopPendingFusionQueueInterval();
        }
        // [queue.weaponTokenID1
        // queue.weaponTokenID2]
      }
    }, ms);
  };

  const toggleWeaponSelectionModal = async () => {
    if (!showWeaponSelectionModal === true) {
      closeWeaponSelectionModalWithConfirm.current = false;
      // will show modal
    } else {
      // will close modal
      if (!closeWeaponSelectionModalWithConfirm.current) {
        setShowWeaponSelectionModal(!showWeaponSelectionModal);
        return;
      }

      const contractWrapper = GetContractWrapper();

      //TODO: calculate amount required for combining
      const minTokenAmountForFusion = ethers.utils.parseEther(combineCost ? combineCost.toString() : "100");

      // check sufficient balance?
      const balance = await contractWrapper.getWcoinBalance(walletAddress);
      if (ethers.BigNumber.from(balance).lt(minTokenAmountForFusion)) {
        alert("Token not enough");
        return;
      }

      // check sufficient approval? and then ask for approval if requires
      let retryApprovalCounter = 0;
      let approvalEnough = false;

      const allowance = await contractWrapper.getWcoinTokenAllowanceForMastery(walletAddress);
      const minAllowanceInWei = ethers.BigNumber.from(minTokenAmountForFusion);
      if (allowance.gte(minAllowanceInWei)) {
        approvalEnough = true;
      }

      if (!approvalEnough) {
        try {
          setIsApproving(true);
          await contractWrapper.approveWcoinTokenToMastery(minAllowanceInWei);
        } catch (ex) {
          if (isMetamaskErrorUserRejected(ex)) {
            alert("user cancel");
            return;
          }
        } finally {
          setIsApproving(false);
        }
      }

      if (weaponSelectionPair.length !== 2) {
        console.warn("weaponSelectionPair must contains 2 weapons");
        return;
      }
      const [nftID1, nftID2] = weaponSelectionPair; // expected that nftID1 and 2 is BigNumber
      const resp = await createFusionQueue(nftID1.toString(), nftID2.toString());
      const { queueID } = resp.data.data;
      if (!queueID) {
        alert("Cannot combine - WeaponMastery Server might has too many requests, please try again later");
        return;
      }

      startPendingFusionQueueInterval(5 * Second);
    }

    setShowWeaponSelectionModal(!showWeaponSelectionModal);
  };

  const toggleCombineModal = () => {
    if (showCombineModal) {
      // will close modal
      if (combineState === CombineState.miniGame) {
        refMinigame.current.stopGame();
      }
    } else {
      if (refMinigame.current) {
        refMinigame.current.resetGame();
      }
    }

    setShowCombineModal(!showCombineModal);
  };

  const toggleCombineButton = payload => {
    setShowCombineButton(payload);
  };

  const getCombineCost = payload => {
    setCombineCost(payload);
  };

  useEffect(() => {
    if (!authenticated) return;
    startPendingFusionQueueInterval(5 * Second);
    return () => stopPendingFusionQueueInterval();
  }, [authenticated]);

  useEffect(() => {
    if (combineState === CombineState.miniGame) {
      if (refMinigame.current) {
        refMinigame.current.resetGame();
      }
    }
  }, [combineState]);

  const spinner = () =>
    <MDBSpinner className='mx-2' color='secondary' size="sm">
      <span className='visually-hidden'>Loading...</span>
    </MDBSpinner>;

  return <>
    <div className="col-md-6 mb-4">
      <div className="card">
        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
          <img src="/wpms/images/choose.jpg" className="img-fluid" />
          <a href="#!">
            <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
          </a>
        </div>
        <div className="card-body">
          <h5 className="card-title">Step 1: Choose Weapons to Combine</h5>
          <p className="card-text">
            The combined weapon is generated from the choosen weapons' data. Its final power is calculated by this
            equation: Final Power = (Weapon#1 Power + Weapon#2 Power + Total Elemental Power) + Combine Power
            Bonus. The weapon can only have 5 elemental types at maximum. Each elemental type can be raised its
            level up to 3.
          </p>
          {
            (() => {
              if (!authenticated) {
                return <a href="#!" className="btn btn-primary" onClick={() => { refConnectWalletFunc.current(); }}>Connect Wallet</a>;
              }
              if (!pendingFusionQueue || pendingFusionQueue.state === FusionQueueState.success) {
                return <a href="#!" className="btn btn-primary" onClick={() => {
                  toggleWeaponSelectionModal();
                }}>Choose weapons and start combining</a>;
              }
              if (pendingFusionQueue.state >= FusionQueueState.readyForFusion) {
                return <a href="#!" className="btn btn-primary disabled">Payment completed, continue to Step 2</a>;
              }
              return <a href="#!" className="btn btn-primary disabled">{spinner()}{fusionQueueStateToString(pendingFusionQueue.state)}</a>;
            })()
          }

          <MDBModal tabIndex='-1' show={showWeaponSelectionModal} setShow={setShowWeaponSelectionModal}>
            <MDBModalDialog className='modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered'>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Combine Weapons</MDBModalTitle>
                  <MDBBtn
                    type='button'
                    className='btn-close'
                    color='none'
                    onClick={toggleWeaponSelectionModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <SelectWeapon authenticated={authenticated} walletAddress={walletAddress}
                    shown={showWeaponSelectionModal}
                    onSelectionChanged={(weapons) => {
                      setWeaponSelectionPair(weapons.map(w => w.tokenID));
                    }} showConfimButtonCallback={toggleCombineButton} getCombineCostCallback={getCombineCost} />
                </MDBModalBody>
                <MDBModalFooter>
                  {
                    showCombineButton === true ?
                      <MDBBtn type={`button ${isApproving ? "disabled" : ""}`} disabled={isApproving} color='secondary' onClick={() => {
                        closeWeaponSelectionModalWithConfirm.current = true;
                        toggleWeaponSelectionModal();
                      }}>
                        {isApproving ? "Approving..." : `Confirm (${combineCost} WCOIN)`}
                      </MDBBtn> : null
                  }
                  <MDBBtn type='button' color='primary' onClick={() => {
                    closeWeaponSelectionModalWithConfirm.current = false;
                    toggleWeaponSelectionModal();
                  }}>
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

        </div>
      </div>
    </div>

    <div className="col-md-6 mb-4">
      <div className="card">
        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
          <img src="/wpms/images/result.jpg" className="img-fluid" />
          <a href="#!">
            <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
          </a>
        </div>
        <div className="card-body">
          <h5 className="card-title">Step 2: Get New Powerful Weapon</h5>
          <p className="card-text">
            Choose 1 out of 3 blueprints that is uniquely generated from your previous weapons. You can combine
            Magic Spell with Melee Weapon or Range Weapon to get the Magic Weapon (Cannot combine Melee together
            with Range). The combine power bonus will be higher if your combined weapon has higher level.
          </p>
          {
            (() => {
              if (!pendingFusionQueue) {
                return <button type="button" className="btn btn-primary disabled">Please complete step 1</button>;
              }

              const { state } = pendingFusionQueue;

              if (state < FusionQueueState.readyForFusion) {
                return <button type="button" className="btn btn-primary disabled">
                  {spinner()}
                  Processing step 1</button>;
              }
              if (state === FusionQueueState.readyForFusion) {
                return <ConfirmFusionButton onClick={() => toggleCombineModal()} />;
              }
              if (state < FusionQueueState.success) {
                return <button type="button" className="btn btn-primary" onClick={() => toggleCombineModal()}>
                  {spinner()}
                  {`Processing (${fusionQueueStateToString(state)})`}</button>
              }
              if (state == FusionQueueState.success) {
                return <button type="button" className="btn btn-primary" onClick={() => { navigate("/inventory") }}>View in inventory</button>
              }
              if (state == FusionQueueState.failed) {
                return <button type="button" className="btn btn-primary warn" onClick={() => { navigate("/inventory") }}>View in inventory</button>
              }
            })()
          }

          <MDBModal tabIndex='-1' show={showCombineModal} setShow={setShowCombineModal}>
            <MDBModalDialog className='modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered'>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Finalize The Combine Weapon</MDBModalTitle>
                  <MDBBtn
                    type='button'
                    className='btn-close'
                    style={{ display: !minigameResult ? 'flex' : 'none' }}
                    color='none'
                    onClick={toggleCombineModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  {
                    (() => {
                      if (!pendingFusionQueue) return null;

                      if (combineState === CombineState.selectImage && pendingFusionQueue.fusionData) {
                        return (
                          <SelectImage
                            fusionData={pendingFusionQueue.fusionData}
                            onSelectImage={(imageURL) => {
                              setSelectedImageURL(imageURL);
                            }} />
                        );
                      } else if (combineState === CombineState.miniGame) {
                        return (
                          <MiniGameSlot ref={refMinigame} image={selectedImageURL} weaponName={pendingFusionQueue.fusionData.name} onDone={(result) => {
                            if (result) {
                              if (refMinigame.current) {
                                refMinigame.current.stopGame();
                              }
                              setMinigameResult(result);
                              setTimeout(function () {
                                (async () => {
                                  const confirmResp = await confirmFusion(pendingFusionQueue.id, selectedImageURL, 0);
                                  if (confirmResp.data) {
                                    if (!confirmResp.data.success) {
                                      alert("Cannot confirm - WeaponMastery Server might has too many requests, please try again later");
                                      return;
                                    }
                                  }
                                  setCombineState(CombineState.submit);
                                  if (refMinigame.current) {
                                    refMinigame.current.stopGame();
                                  }
                                  startPendingFusionQueueInterval(5 * Second);
                                  setMinigameResult(false);
                                })();
                              }, 2000);
                            }
                          }} />
                        );
                        // if (!showMinigame) {
                        //   return (
                        //     <MiniGameSlot ref={refMinigame} image={selectedImageURL} weaponName={pendingFusionQueue.fusionData.name} onDone={(result) => {
                        //       if (result) {
                        //         if (refMinigame.current) {
                        //           refMinigame.current.stopGame();
                        //         }
                        //         console.log("miniGameResult:", result);
                        //         setMinigameResult(true);
                        //         setShowMinigame(true);
                        //       } else {
                        //         if (refMinigame.current) {
                        //           refMinigame.current.stopGame();
                        //         }
                        //         setMinigameResult(false);
                        //         setShowMinigame(true);
                        //       }
                        //     }} />

                        //   );
                        // } else {
                        //   if (minigameResult) {
                        //     setTimeout(function () {
                        //       (async () => {
                        //         await confirmFusion(pendingFusionQueue.id, selectedImageURL, 0);
                        //         setCombineState(CombineState.submit);
                        //         if (refMinigame.current) {
                        //           refMinigame.current.stopGame();
                        //         }
                        //         startPendingFusionQueueInterval(5 * Second);
                        //         setShowMinigame(false);
                        //         setMinigameResult(false);
                        //       })();
                        //     }, 1000);
                        //     return (
                        //       <>
                        //         <MDBRow className='text-center'>
                        //           <MDBModalTitle>Completed!</MDBModalTitle>
                        //         </MDBRow>
                        //       </>
                        //     )
                        //   } else {
                        //     return (
                        //       <>
                        //         <MDBRow className='text-center'>
                        //           <MDBBtn
                        //             type='button'
                        //             onClick={() => {
                        //               setShowMinigame(false);
                        //               setMinigameResult(false);
                        //               if (refMinigame.current) {
                        //                 refMinigame.current.resetGame();
                        //               }
                        //             }}
                        //           >Try Again</MDBBtn>
                        //         </MDBRow>
                        //       </>);
                        //   }
                        // }
                      } else if (combineState === CombineState.submit) {
                        return <FusionProgress queue={pendingFusionQueue} />
                      }
                      return null;
                    })()
                  }
                </MDBModalBody>
                <MDBModalFooter>
                  {
                    (() => {
                      if (combineState === CombineState.selectImage && selectedImageURL.length > 0) {
                        return <MDBBtn type='button' color='secondary' onClick={() => {
                          setCombineState(CombineState.miniGame);
                        }}>
                          Continue
                        </MDBBtn>;
                      }
                      return null;
                    })()
                  }
                  {
                    (combineState === CombineState.miniGame && !minigameResult) ?
                      <MDBBtn type='button' color='secondary' onClick={() => {
                        setCombineState(CombineState.selectImage);
                        if (refMinigame.current) {
                          refMinigame.current.stopGame();
                        }
                        setSelectedImageURL("");
                      }}>
                        Back
                      </MDBBtn> : <></>
                  }
                  {
                    (combineState === CombineState.miniGame && minigameResult) ?
                      <></> : <MDBBtn type='button' color='primary' onClick={toggleCombineModal}>
                        Close
                      </MDBBtn>
                  }

                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </div>
      </div>
    </div>
  </>
}

function SelectWeapon(props) {
  const { authenticated, walletAddress, shown, onSelectionChanged, showConfimButtonCallback, getCombineCostCallback } = props;
  const [weapons, setWeapons] = useState([]);
  const contractWrapper = GetContractWrapper();
  const [selectedWeapons, setSelectedWeapons] = useState([]);

  const [previewWeapon, setPreviewWeapon] = useState(null);
  const [previewError, setPreviewError] = useState("");

  useEffect(() => {
    if (!authenticated) return;
    if (!walletAddress) return;
    if (!shown) return;
    reload();
    setSelectedWeapons([]);
  }, [authenticated, walletAddress, shown]);

  const reload = async () => {
    const tokenIDs = await contractWrapper.getAllWeaponTokens(walletAddress);
    const weapons = [];

    for (const id of tokenIDs) {
      const uri = await contractWrapper.getWeaponTokenURI(id);
      if (uri.endsWith(".json")) continue; // token uri with IPFS could not ends with .json
      const resp = await axios.get(uri);
      const { data } = resp;
      const weapon = {
        tokenID: id,
        ...data,
      };

      weapons.push(weapon);
    }
    setWeapons(weapons);
  };

  const isSelected = (weapon) => {
    for (const sw of selectedWeapons) {
      if (sw.tokenID.toString() === weapon.tokenID.toString()) {
        return true;
      }
    }
    return false;
  }

  const updatePreviewWeapon = async (weapons) => {
    if (weapons.length !== 2) return;
    const [nftID1, nftID2] = weapons.map(w => w.tokenID);
    const resp = await previewFusion(nftID1.toString(), nftID2.toString());

    if (!resp.data.success) {
      setPreviewWeapon(null);
      setPreviewError(resp.data.errors)
      showConfimButtonCallback(false);
    } else {
      const weapon = resp.data.data.weapon;
      setPreviewWeapon(weapon);
      setPreviewError(null)

      if (weapon) {
        getCombineCostCallback(weapon.combineCost)
        showConfimButtonCallback(true);
      }
    }
  }

  return <>
    <div className="row">

      <h3>Choose 2 weapons to combine</h3>

      <div className="row">
        {
          weapons.filter((w) => !isSelected(w)).map((weapon) => (
            <div key={weapon.tokenID} className="col-lg-2 col-md-3 my-2">
              <div className="bg-image hover-overlay ripple shadow-1-strong rounded" data-mdb-ripple-color="light">
                <img src={replaceURLToInfuraGateway(weapon.image)} className="w-100" alt="Louvre" />
                <a href="#!" onClick={() => {
                  if (selectedWeapons.length >= 2) return;

                  selectedWeapons.push(weapon);
                  setSelectedWeapons(selectedWeapons);
                  if (onSelectionChanged) onSelectionChanged(selectedWeapons);
                  if (selectedWeapons.length === 2) {
                    updatePreviewWeapon(selectedWeapons);
                  }
                }}>
                  <div className="mask" style={{ "backgroundColor": "hsla(0, 0%, 98%, 0.2)" }}></div>
                </a>
              </div>
            </div>
          ))
        }
      </div>

      <hr className="my-4" />

      <div className="col-md-12 my-2">

        <h3 className="mb-4">Chosen weapons</h3>
        <p>{selectedWeapons.length > 0 ? "" : "-"}</p>

        <div className="row">
          {
            selectedWeapons.map((weapon) => (

              <div key={weapon.tokenID} className="col-md-3 mb-2">
                <div className="bg-image hover-overlay ripple shadow-1-strong rounded" data-mdb-ripple-color="light">
                  <img src={replaceURLToInfuraGateway(weapon.image)} className="w-100" alt="Louvre" />
                  <a href="#!" onClick={() => {
                    const idx = selectedWeapons.indexOf(weapon);
                    selectedWeapons.splice(idx, 1);
                    setSelectedWeapons(selectedWeapons);
                    if (onSelectionChanged) onSelectionChanged(selectedWeapons);
                  }}>
                    <div className="mask" style={{ "backgroundColor": "hsla(0, 0%, 98%, 0.2)" }}></div>
                  </a>
                </div>
                <p>{"Weapon#" + (selectedWeapons.indexOf(weapon) + 1)}</p>
              </div>
            ))
          }

          <div className="col-md-6 mb-2">
            {
              (selectedWeapons.length === 2) ? <>
                {
                  (previewWeapon) ? <>
                    <hr className="my-0 mb-4" />
                    <h5 className="mb-4">Combine Result</h5>
                    <hr className="mb-3" />
                    <p>Weapon Type : <span style={{ color: weaponTypeToColor(previewWeapon.type) }}>{weaponTypeToString(previewWeapon.type)}</span></p>
                    <p>Weapon Class : {previewWeapon.class}</p>
                    <p>Rarity : <span style={{ color: weaponRarityToColor(previewWeapon.possibleRarity[previewWeapon.possibleRarity.length - 1]) }}>{weaponRarityToString(previewWeapon.rarity)}</span></p>
                    <p>Elemental : <span style={{ color: weaponTypeToColor(2) }}>{previewWeapon.elementalText}</span></p>
                    <p>Power : {previewWeapon.powerRange}
                      <ul>
                        <li>Raw Power : {previewWeapon.rawPower}</li>
                        <li>Elemental Power : {(previewWeapon.totalElementalPower > 0 ? previewWeapon.totalElementalPower + " x 2" : "0")}</li>
                        <li>Combine Power Bonus : <span style={{ color: weaponRarityToColor(previewWeapon.possibleRarity[previewWeapon.possibleRarity.length - 1]) }}>{previewWeapon.bonusPowerRange}</span></li>
                      </ul>
                    </p>
                    {/* {previewWeapon.elementalPower.map(ep => `${elementalTypeToString(ep.elementalType)} ${ep.elementalActualPower} (${ep.elementalLevel})`).join(", ")} */}
                    <p>*The weapon can only have 5 elemental types at maximum.<br />
                      *If confirm, the chosen weapons wil be burnt. You cannot undo this action.</p>
                  </> :
                    <>
                      <hr className="my-0 mb-4" />
                      <h5 className="mb-4">Combine Result</h5>
                      <hr className="mb-3" />
                      <ul>
                        <li>
                          {previewError}
                        </li>
                      </ul>
                    </>
                }
              </> : <>
                {
                  showConfimButtonCallback(false)
                }
              </>
            }
          </div >

        </div >
      </div >

    </div >
  </>
}

function ConfirmFusionButton(props) {
  const { onClick } = props;
  return (<button
    type="button" className="btn btn-primary"
    onClick={onClick}>
    Finalize The Combine Weapon
  </button>);
}

function GetSelectBorder(selectedImageURL, imageURL) {
  var style = ""
  if (selectedImageURL === imageURL) {
    style = { backgroundColor: "#00dfff", borderStyle: "solid", borderColor: "#00dfff", borderWidth: "5px" }
  } else {
    style = { backgroundColor: "#424242" }
  }
  return style
}

function SelectImage(props) {
  const { fusionData, onSelectImage } = props;
  const [selectedImageURL, setSelectedImageURL] = useState('');

  const { generatedImageURLs: imageURLs } = fusionData;

  const handleImageClick = (imageURL) => {
    onSelectImage(imageURL);
  };

  const raritySpan = weaponRarityToString(fusionData.rarity) === "Common" ? null : <span style={{ color: weaponRarityToColor(fusionData.rarity) }}>{weaponRarityToString(fusionData.rarity)}</span>
  return (
    <>

      <MDBRow className='text-center'>
        <MDBCol lg={10} className='d-grid gap-2 col-md-8 my-2 mx-auto'>
          <p>A new {weaponRarityToString(fusionData.rarity)} <span style={{ color: weaponTypeToColor(fusionData.type) }}>{weaponTypeToString(fusionData.type)}</span> weapon has been discovered!</p>
          <h3>Please select a blueprint for "<span style={{ color: weaponRarityToColor(fusionData.rarity) }}>{fusionData.name}</span>"</h3>
          <p>{fusionData.description}</p>
        </MDBCol>
      </MDBRow>

      <hr className="my-4" />

      <MDBRow className='text-center'>
        <div className="mb-4 ">
          <button type="button" className="btn btn-danger btn-lg "
            style={{ height: "100%", minHeight: "40px" }}>
            <i className="fa fa-exclamation me-3"></i>Please make sure you have both weapons from Step-1 in your inventory.
          </button>
        </div>
      </MDBRow>

      <MDBRow>
        {imageURLs.map((imageURL, index) => (
          <MDBCol md='4' key={imageURL}>
            <MDBCard onClick={() => {
              setSelectedImageURL(imageURL)
              handleImageClick(imageURL);
            }} style={GetSelectBorder(selectedImageURL, imageURL)}>
              <MDBCardImage src={imageURL} />
              <MDBCardBody>
                <MDBCardTitle style={{ color: selectedImageURL === imageURL ? "#424242" : "white" }}>{`Blueprint #${index + 1}`}</MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
    </>
  );
}

function FusionProgress(props) {
  const { queue } = props;
  const [imageURL, setImageURL] = useState("");
  useEffect(() => {
    if (!queue.fusionData) return;
    if (!queue.fusionData.tokenID) return;
    (async () => {
      const contractWrapper = GetContractWrapper();
      const tokenURI = await contractWrapper.getWeaponTokenURI(queue.fusionData.tokenID);
      const resp = await axios.get(tokenURI);
      setImageURL(replaceURLToInfuraGateway(resp.data.image));
    })();
  }, [queue.fusionData.tokenID]);

  return <MDBRow className='text-center'> {
    (() => {
      if (queue.state < FusionQueueState.success) {
        return (
          <MDBCol lg={10} className='d-grid gap-2 col-md-8 my-2 mx-auto'>
            <h5>Combining weapons... (Progress: {fusionQueueStateToString(queue.state)})</h5>
            The process is working in background. You can close this window and check your inventory later.
          </MDBCol>
        );
      } else if (queue.state === FusionQueueState.success && imageURL.length > 0) {
        return (
          <div className='d-grid gap-2 col-md-4 my-2 mx-auto'>
            <h5>You've received a new weapon!</h5>
            <div className="bg-image hover-overlay ripple shadow-1-strong rounded" data-mdb-ripple-color="light">
              <img src={imageURL} className="w-100" alt="Louvre" />
              <a href="#!">
                <div className="mask" style={{ "backgroundColor": "hsla(0, 0%, 98%, 0.2)" }}></div>
              </a>
            </div>
          </div>
        );
      } else if (queue.state === FusionQueueState.failed) {
        return (
          <MDBCol lg={10}>
            <h5>Failed to combine Weapons</h5>
          </MDBCol>
        );
      }
    })()}
  </MDBRow>;
}