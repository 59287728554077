import { AuthContext } from "@ryanar/react-auth-provider";
import { ContractAddressBook } from "../constants";
import { ContractWrapper, GetContractWrapper } from "../contractwrapper/contractWrapper";
import { BigImageIntro } from "./components/bigImageIntro";
import { CombineWeaponComponent } from "./components/combine";
import { DungeonInfoComponent, MockupDungeonInfoComponent } from "./components/dungeonInfo";
import { ForgingComponent } from "./components/forging";
import { goalComponent } from "./components/goal";
import { introComponent } from "./components/intro";
import { OverviewComponent } from "./components/overview";
import { PreviewWeaponCard } from "./components/previewWeaponCard";
import { roadmapComponent } from "./components/roadmap";

import { ethers } from "ethers";
import { MDBBtn, MDBInput, MDBModal, MDBRow, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import { useWeb3React } from "@web3-react/core";
import { formatNumber, isMetamaskErrorUserRejected } from "../utilities";
import { ConnectWalletContext } from "./components/ConnectWalletContext";
import { getLeaderboards } from "./api/nft";
import React, { useContext, useEffect, useState } from "react";
import { getReadOnlyJSONRPCProvider } from "./web3/metaMask";
import { currentChain } from "./web3/chain";

const weaponRarityTable = [
  {
    rarity: "common",
    startingPower: 100,
    combinePowerBonus: "0 - 20",
    combineCost: 20,
    style: "outline-light",
  },
  {
    rarity: "great",
    startingPower: 300,
    combinePowerBonus: "20 - 50",
    combineCost: 60,
    style: "outline-success",
  },
  {
    rarity: "rare",
    startingPower: "1,000",
    combinePowerBonus: "60 - 100",
    combineCost: 120,
    style: "outline-info",
  },
  {
    rarity: "epic",
    startingPower: "4,000",
    combinePowerBonus: "130 - 180",
    combineCost: 200,
    style: "outline-secondary",
  },
  {
    rarity: "legendary",
    startingPower: "10,000",
    combinePowerBonus: "220 - 280",
    combineCost: 280,
    style: "outline-warning",
  },
  {
    rarity: "master",
    startingPower: "22,000",
    combinePowerBonus: "Can't combine",
    combineCost: 340,
    style: "outline-danger",
  }
];


function Home() {
  const { authenticated } = React.useContext(AuthContext);
  const { account: walletAddress } = useWeb3React();

  const [showConvertWCoinModal, setShowConvertWCoinModal] = useState(false);
  const [currentAllowance, setCurrentAllowance] = useState(ethers.BigNumber.from(0));
  const [hasApprovedWCoinToVendor, setHasApprovedWCoinToVendor] = useState(false);
  const [isApprovingWCoinToVendorInProgress, setIsApprovingWCoinToVendorInProgress] = useState(false);
  const { refConnectWalletFunc } = useContext(ConnectWalletContext);
  const [convertWCoinInProgress, setConvertWCoinInProgress] = useState(false);

  // leaderboardRecords: { position, nftId, playerAddress, stakingPower }
  const [leaderboardRecords, setLeaderboardRecords] = useState([]);

  const [conversionInfo, setConversionInfo] = useState({
    kkubPrice: ethers.BigNumber.from(0),
  });
  const [kkubPricePerWCoin, setKkubPricePerWCoin] = useState("0");
  const readonlyContractWrapper = GetContractWrapper();

  let didInit = false;
  useEffect(() => {
    if (!didInit) {
      didInit = true;
      updateWCoinPrice();
      (async () => {
        const resp = await getLeaderboards();
        if (resp.status !== 200) return;

        const leaderboard = resp.data.data.leaderboards[2];
        const records = [];
        if (leaderboard) {
          for (const [idx, record] of leaderboard.leaderboardRecord.entries()) {
            const { nftId, playerAddress, stakingPower, imageURL } = record;
            const position = idx + 1;
            records.push({ position, nftId, playerAddress, stakingPower, imageURL });
          }
        }
        setLeaderboardRecords(records);
      })();
    }
  }, []);

  const updateWCoinPrice = () => {
    (async () => {
      const wcoinPriceInKKUB = await readonlyContractWrapper.vendorAmountOut(ethers.utils.parseEther("1"));
      setConversionInfo({
        kkubPrice: wcoinPriceInKKUB,
      });
      setKkubPricePerWCoin(formatNumber(wcoinPriceInKKUB, 6))
    })();
  };

  useEffect(() => {
    if (!walletAddress) return;
    (async () => {
      const contractWrapper = GetContractWrapper();
      const allowance = await contractWrapper.getWcoinTokenAllowanceForVendor(walletAddress);
      setCurrentAllowance(ethers.BigNumber.from(allowance));
      setHasApprovedWCoinToVendor(allowance.gt(ethers.utils.parseEther("1000")));
    })()
  }, [walletAddress]);


  return (
    <>
      <BigImageIntro />

      <div className="container my-5">

        <section id="about">
          {introComponent()}

          { /* Grid row */}
          <div className="row">
            { /* Grid column */}
            <div className="col-md-8 mb-4">
              <OverviewComponent weaponRarityTable={weaponRarityTable} />
            </div>
            { /* Grid column */}

            { /* Grid column */}
            <div className="col-md-4 mb-4">
              {goalComponent()}
              {roadmapComponent()}
            </div>
            { /* Grid column */}

          </div>
          { /* Grid row */}
        </section >


        <section id="create">
          <hr className="my-5" />
          <div className="text-center">
            <h2><strong>Forging Station</strong></h2>
            <p>Create your own weapon (common, gen#1). Each with unique power and characteristic.<br />
            </p>
          </div>
          <hr className="my-5" />

          <div className="row">
            <ForgingComponent />
          </div>
        </section>

        <section id="combine">
          <hr className="my-5" />
          <div className="text-center">
            <h2><strong>Combine Weapons</strong></h2>
            <p>Combine two weapons together, to get more powerful one.</p>
          </div>
          <hr className="my-5" />

          <div className="row">
            <CombineWeaponComponent />
          </div>
        </section>

        <section id="mining">
          <hr className="my-5" />
          <div className="text-center">
            <h2><strong>Available Dungeons</strong></h2>
            <p>Stake your weapon NFT in theese mining pools to get WCOIN!</p>
          </div>
          <hr className="my-5" />
          { /* Grid row */}
          <div className="row">

            <div className="mb-4">
              <button type="button" className="btn btn-outline-warning btn-lg btn-block ripple-surface"
                style={{ height: "100%", minHeight: "100px" }} onClick={updateWCoinPrice}>
                <h5><i className="fa fa-coins me-2"></i>WCOIN Price = {kkubPricePerWCoin} KKUB</h5>
                The price will go higher for each and every gen#1 weapon be created.
              </button>
            </div>

            { /* Grid column */}
            <div className="col-lg-4 col-md-12 mb-4">
              {
                ContractAddressBook.Farms.RobotSwamp === ethers.constants.AddressZero ?
                  <MockupDungeonInfoComponent
                    imageURL="/wpms/images/d3.jpg"
                    farmName="Robot Swamp"
                    miningPeriodBlock="Q1, 2023"
                    totalMiningReward="250,000"
                    miningRate="0"
                    totalMiningPower="0"
                    yourMiningPower="0"
                    weaponReward={{
                      info: "Animated Magic Weapon (5 elemental)",
                      buttonStyle: "btn-outline-warning"
                    }} />
                  :
                  <DungeonInfoComponent
                    farmName="Robot Swamp"
                    farmAddress={ContractAddressBook.Farms.RobotSwamp}
                    imageURL="/wpms/images/d1.jpg"
                    comingSoonText="Q1, 2023"
                    startBlock="12757295"
                    endBlock="14357295"
                    dungeonStartDate="24/05/2023"
                    dungeonEndDate="25/08/2023"
                    weaponReward={{
                      info: "Rare Magic Spell (Earth: 5)",
                      buttonStyle: "btn-outline-info"
                    }} />
              }
            </div>
            { /* Grid column */}

            { /* Grid column */}
            <div className="col-lg-4 col-md-6 mb-4">
              {
                ContractAddressBook.Farms.SkyFactory === ethers.constants.AddressZero ?
                  <MockupDungeonInfoComponent
                    imageURL="/wpms/images/d2.jpg"
                    farmName="Sky Factory"
                    miningPeriodBlock="Q3, 2023"
                    totalMiningReward="150,000"
                    miningRate="0"
                    totalMiningPower="0"
                    yourMiningPower="0"
                    weaponReward={{
                      info: "Epic Magic Spell (Wind: 5, Thunder: 5)",
                      buttonStyle: "btn-outline-secondary"
                    }} /> :
                  <DungeonInfoComponent
                    farmName="Sky Factory"
                    farmAddress={ContractAddressBook.Farms.SkyFactory}
                    imageURL="/wpms/images/d2.jpg"
                    comingSoonText="Q2 2023"
                    startBlock="14501036"
                    endBlock="16101036"
                    dungeonStartDate="02/09/2024"
                    dungeonEndDate="04/12/2024"
                    weaponReward={{
                      info: "Epic Magic Spell (Wind: 5, Thunder: 5)",
                      buttonStyle: "btn-outline-secondary"
                    }} />
              }
            </div>
            { /* Grid column */}

            { /* Grid column */}
            <div className="col-lg-4 col-md-6 mb-4">
              {
                ContractAddressBook.Farms.DeathStar === ethers.constants.AddressZero ?
                  <MockupDungeonInfoComponent
                    imageURL="/wpms/images/d3.jpg"
                    farmName="Death Star"
                    miningPeriodBlock="Q4, 2023"
                    totalMiningReward="4000,000"
                    miningRate="0"
                    totalMiningPower="0"
                    yourMiningPower="0"
                    weaponReward={{
                      info: "Animated Magic Weapon (5 elemental)",
                      buttonStyle: "btn-outline-warning"
                    }} /> :
                  <DungeonInfoComponent
                    farmName="Death Star"
                    farmAddress={ContractAddressBook.Farms.DeathStar}
                    imageURL="/wpms/images/d3.jpg"
                    comingSoonText="Q1 2024"
                    startBlock="16591788"
                    endBlock="18665388"
                    dungeonStartDate="01/01/2024"
                    dungeonEndDate="30/04/2024"
                    weaponReward={{
                      info: "Animated Magic Weapon (5 elemental)",
                      buttonStyle: "btn-outline-warning"
                    }} />
              }
            </div>
            { /* Grid column */}
          </div>
          { /* Grid row */}
        </section >


        <section id="leaderboard">
          <hr className="my-5" />
          <div className="text-center">
            <h2><strong>Top 10 Most Powerful Weapons</strong></h2>
            <p>Only weapons in current active mining pool are ranked. The owners will get special reward at the end of the mining period. (update every hour)</p>
          </div>

          <hr className="my-5" />

          {
            leaderboardRecords.length === 0 ?
              null :
              (() => {
                const nCols = 4;
                let totalItems = leaderboardRecords.length;
                if (authenticated) {
                  totalItems += 1; // + 1 for last item which is create new weapon
                }
                const nRows = Math.floor(totalItems / nCols) + ((totalItems % nCols > 0) ? 1 : 0);
                const isLastItem = (idx) => idx === (totalItems - 1);

                const buildNewWeaponComponent =
                  <div className="col-lg-3 col-md-6 mb-4" key="buildNewWeapon">
                    <div className="card text-center" style={{ "height": "100%" }}>
                      <div className="card-header">Weapon Mastery</div>
                      <div className="card-body">
                        <h4 className="card-title">Build your own Weapon!</h4><br />
                        <p className="card-text">Weapon Mastery uses AI to create unique weapon-NFTs.
                        </p>
                        <br />
                        <a href="#create" className="btn btn-primary">Create Now</a>
                      </div>
                      <div className="card-footer text-muted">Melee/Range/Spell</div>
                    </div>
                  </div>;

                let remaining = totalItems;
                const rows = [];
                for (let r = 0; r < nRows; r++) {
                  const cols = [];
                  for (let c = 0; c < nCols; c++) {
                    const itemIdx = (r * nCols) + c;
                    const record = leaderboardRecords[itemIdx];
                    cols.push(
                      isLastItem(itemIdx) && authenticated ?
                        buildNewWeaponComponent :
                        <div key={itemIdx} className="col-lg-3 col-md-6 mb-4">
                          {
                            <PreviewWeaponCard imageURL={`${record.imageURL}`} no={`${itemIdx + 1}`} playerAddress={`${record.playerAddress}`} />
                          }
                        </div>
                    );
                    remaining--;
                    if (remaining === 0) break;
                  }
                  rows.push(
                    <div className="row" key={r}>
                      {cols}
                    </div>
                  );
                }
                return <>{rows}</>;
              })()
          }
        </section>

        <section id="wcoin">
          <hr className="my-5" />
          <div className="text-center">
            <h2><strong>WCOIN</strong></h2>
            <p>WCOIN is the native utility token of the game which the players can gain through mining with the Weapon
              NFT.</p>
          </div>
          <hr className="my-5" />
          { /* Grid row */}
          <div className="row">
            { /* Grid column */}
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="card">
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="/wpms/images/wcoin.jpg" className="img-fluid" />
                  <a href="#!">
                    <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
                  </a>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Tokenomics</h5>
                  <p className="card-text">
                    The total supply of WCOIN is 1,000,000 of which 500,000 WCOIN will be supplied as mining rewards, and
                    the remaining goes to the developer.
                  </p>
                  <a href="#mining" className="btn btn-primary">Mining</a>
                </div>
              </div>
            </div>
            { /* Grid column */}

            { /* Grid column */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card">
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="/wpms/images/combine.jpg" className="img-fluid" />
                  <a href="#!">
                    <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
                  </a>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Combine Weapons</h5>
                  <p className="card-text">
                    The main utility of WCOIN is to be used for combining process. The required amount depends on the
                    rarity of the material weapons.
                  </p>
                  <a href="#combine" className="btn btn-primary">Combine Weapon</a>
                </div>
              </div>
            </div>
            { /* Grid column */}

            { /* Grid column */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card">
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="/wpms/images/convert.jpg" className="img-fluid" />
                  <a href="#!">
                    <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
                  </a>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Convert to KKUB</h5>
                  <p className="card-text">
                    WCOIN can be converted (and burnt after) to KKUB Coin with 1 WCOIN = Total KKUB received form gen#1
                    weapon creation / Total supply of WCOIN.
                  </p>
                  {
                    (() => {
                      if (!authenticated) return (
                        <a href="#!" className="btn btn-primary" onClick={() => { refConnectWalletFunc.current(); }}>Connect Wallet
                        </a>
                      );
                      if (convertWCoinInProgress) return (
                        <a href="#!" className="btn btn-primary disabled" >Convert in Progress...</a>
                      );
                      if (hasApprovedWCoinToVendor) return (
                        <a href="#!" className="btn btn-primary" onClick={() => { setShowConvertWCoinModal(true) }}>Convert your WCOIN</a>
                      )
                      if (isApprovingWCoinToVendorInProgress) return (
                        <button className="btn btn-primary disabled">Approving...</button>
                      )
                      return (
                        <a href="#!" className="btn btn-primary" onClick={async () => {
                          const contractWrapper = GetContractWrapper();
                          try {
                            setIsApprovingWCoinToVendorInProgress(true);
                            await contractWrapper.approveWcoinTokenToVendor(ethers.utils.parseEther("1000000"));
                            setHasApprovedWCoinToVendor(true);
                          } catch (err) {
                          } finally {
                            setIsApprovingWCoinToVendorInProgress(false);
                          }
                        }}>Approve WCOIN to convert</a>
                      );
                    })()
                  }
                </div>
              </div>
            </div>

            <ConvertWCoinModal
              walletAddress={walletAddress}
              showConvertWCoinModal={showConvertWCoinModal}
              setShowConvertWCoinModal={setShowConvertWCoinModal}
              onConvertConfirmed={async (amount) => {
                console.log(`convert ${amount} wcoin to kkub`);
                const contractWrapper = GetContractWrapper();
                try {
                  setConvertWCoinInProgress(true);
                  await contractWrapper.vendorSellWCoin(amount);
                } catch (err) {
                  if (isMetamaskErrorUserRejected(err)) {
                    alert("user rejected the transaction");
                    return;
                  }
                } finally {
                  setConvertWCoinInProgress(false);
                }
              }}
              onApproveConfirmed={async (amount) => {
                console.log(`approve ${ethers.utils.formatEther(amount)} wcoin to vendor`);
                const contractWrapper = GetContractWrapper();
                try {
                  setIsApprovingWCoinToVendorInProgress(true);
                  await contractWrapper.approveWcoinTokenToVendor(amount);
                  setHasApprovedWCoinToVendor(true);
                  const allowance = await contractWrapper.getWcoinTokenAllowanceForVendor(walletAddress);
                  setCurrentAllowance(ethers.BigNumber.from(allowance));
                } catch (err) {
                } finally {
                  setIsApprovingWCoinToVendorInProgress(false);
                }
              }} />
            { /* Grid column */}
          </div>
          { /* Grid row */}
        </section>
      </div >
    </>
  );


  function ConvertWCoinModal({
    walletAddress,
    showConvertWCoinModal,
    setShowConvertWCoinModal,
    onConvertConfirmed,
    onApproveConfirmed,
  }) {

    const toggleModal = async () => setShowConvertWCoinModal(!showConvertWCoinModal);
    const [conversionInfo, setConversionInfo] = useState({
      wcoinBalance: ethers.BigNumber.from(0),
      kkubToReceived: ethers.BigNumber.from(0),
      wcoinPrice: ethers.BigNumber.from(0),
    });
    const [amount, setAmount] = useState("0");
    const readonlyContractWrapper = GetContractWrapper();
    const [currentAllowance, setCurrentAllowance] = useState(ethers.BigNumber.from(0));

    useEffect(() => {
      if (!walletAddress) return;

      (async () => {
        const balance = await readonlyContractWrapper.getWcoinBalance(walletAddress);
        const priceWcoinPer1kkub = await readonlyContractWrapper.vendorAmountOut(ethers.utils.parseEther("1"));
        const kkubToReceived = 0;

        const allowance = await readonlyContractWrapper.getWcoinTokenAllowanceForVendor(walletAddress);
        setCurrentAllowance(ethers.BigNumber.from(allowance));

        setConversionInfo({
          wcoinBalance: balance,
          kkubToReceived,
          wcoinPrice: priceWcoinPer1kkub,
        });

      })();
    }, [walletAddress]);

    const updateKKUBToReceived = () => {
      if (amount.length === 0) {
        setConversionInfo({
          ...conversionInfo,
          kkubToReceived: ethers.BigNumber.from(0),
        });
        return;
      }
      let amount_ = ethers.utils.parseEther("0");

      try {
        amount_ = ethers.utils.parseEther(amount);
        if (amount_.lt(0)) {
          setConversionInfo({
            ...conversionInfo,
            kkubToReceived: ethers.BigNumber.from(0),
          });
          return;
        }
      } catch (err) {
        setConversionInfo({
          ...conversionInfo,
          kkubToReceived: ethers.BigNumber.from(0),
        });
        return;
      }

      const kkubToReceived = amount_.mul(conversionInfo.wcoinPrice).div(ethers.utils.parseEther("1"));
      setConversionInfo({
        ...conversionInfo,
        kkubToReceived,
      });
    };

    useEffect(() => {
      updateKKUBToReceived();
    }, [amount]);

    return (
      <MDBModal tabIndex='-1' show={showConvertWCoinModal} setShow={setShowConvertWCoinModal}>
        <MDBModalDialog className='modal-dialog modal-dialog-centered'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Convert WCOIN to KKUB</MDBModalTitle>
              <MDBBtn
                type='button'
                className='btn-close'
                color='none'
                onClick={toggleModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody><MDBRow className='text-center mx-2'>
              <p>Your balance: {formatNumber(conversionInfo.wcoinBalance, 6)} WCOIN</p>
              <p>WCOIN price (estimated): {formatNumber(conversionInfo.wcoinPrice, 6)} KKUB</p>
              <p className="mb-2">Amount you want to convert (WCOIN):</p>
              <br />
              <MDBInput className='text-center me-4' type='number' value={amount} onChange={(v) => setAmount(v.target.value)} />
              <p className="my-2">KKUB to be receiced (estimated): {formatNumber(conversionInfo.kkubToReceived, 6)}</p>
            </MDBRow></MDBModalBody>
            <MDBModalFooter>
              {
                ethers.utils.parseEther(amount.length === 0 ? "0" : amount).lte(currentAllowance) ?
                  <MDBBtn type='button' color='secondary' onClick={() => {
                    toggleModal();
                    if (onConvertConfirmed) onConvertConfirmed(ethers.utils.parseEther(amount.length === 0 ? "0" : amount));
                  }}>
                    Convert
                  </MDBBtn> :
                  <MDBBtn type='button' color='secondary' onClick={() => {
                    toggleModal();
                    if (onApproveConfirmed) onApproveConfirmed(ethers.utils.parseEther(amount.length === 0 ? "0" : amount));
                  }}>
                    Approve
                  </MDBBtn>
              }

              <MDBBtn type='button' color='primary' onClick={() => {
                toggleModal();
              }}>
                Cancel
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

export default Home