import React from "react";
import { useSearchParams } from "react-router-dom";
import './CardTemplate.css'
import common_Border from './cardTemplate/Common_Border.png'
import great_Border from './cardTemplate/Great_Border.png'
import rare_Border from './cardTemplate/Rare_Border.png'
import epic_Border from './cardTemplate/Epic_Border.png'
import legendary_Border from './cardTemplate/Legendary_Border.png'
import master_Border from './cardTemplate/Master_Border.png'

import './fonts/Atami-Display.otf';
import './fonts/Atami-Light.otf';

function CardTemplate() {
  const [searchParams, setSearchParam] = useSearchParams();
  const name = searchParams.get("name");
  const bonusLevel = searchParams.get("bonusLevel");
  const type = searchParams.get("type");
  const power = searchParams.get("power");
  const rarity = searchParams.get("rarity");
  const description = searchParams.get("description");
  const gen = searchParams.get("gen");
  const elemental = searchParams.get("elementals");
  const image = searchParams.get("imageURL");

  var rarityString = ""
  if (rarity !== "Common") {
    rarityString = rarity + " "
  } else {
    rarityString = ""
  }

  var elementalString = "No elemental damage"
  if (elemental !== "") {
    elementalString = elemental
  }

  var bonusLevelString = ""
  if (bonusLevel !== "") {
    bonusLevelString = " " + bonusLevel
  }

  return (
    <div id="card-background-wrapper">
      <img src={GetBorderRarity(rarity)} alt="cardBackground"></img>
      <div id="card-inner-border">
        <div id="card-image-wrapper">
          <img src={image} alt="cardImage"></img>
        </div>
        <div id="card-name">
          <p style={{ fontSize: ResizeFont(name + bonusLevelString, 32, 20), color: GetNameColor(rarity) }}>
            {name}{bonusLevelString}
          </p>
        </div>
        <div id="card-power">
          <p style={{ fontSize: ResizeFont(rarityString + type + " : " + power, 28, 32), color: GetTypeColor(type)}}>
            {rarityString}{type} : {power}
          </p>
        </div>
        <div id="card-description">
          <p style={{ fontSize: ResizeFont(description + " gen#" + gen, 22, 150)}}>
            {description} (gen#{gen})
          </p>
        </div>
        <div id="card-elemental">
          <p style={{ fontSize: ResizeFont(elementalString, 22, 30) }}>
            {elementalString}
          </p>
        </div>
      </div>
    </div>
  );
}

function GetNameColor(rarity) {
  switch (rarity) {
    case 'Common':
      return "#ffffff";
    case 'Great':
      return "#66f000";
    case 'Rare':
      return "#00f0ff";
    case 'Epic':
      return "#e467ff";
    case 'Legendary':
      return "#ff9600";
    case 'Master':
      return "#ff008a";
    default:
      return '';
  }
}

function GetTypeColor(type) {
  switch (type) {
    case 'Melee':
      return "#79b15b";
    case 'Magic Melee':
      return "#79b15b";
    case 'Range':
      return "#cc9b4f";
    case 'Magic Range':
      return "#cc9b4f";
    case 'Magic Spell':
      return "#49cac8";
    default:
      return '';
  }
}


function GetBorderRarity(rarity) {
  switch (rarity) {
    case 'Common':
      return common_Border;
    case 'Great':
      return great_Border;
    case 'Rare':
      return rare_Border;
    case 'Epic':
      return epic_Border;
    case 'Legendary':
      return legendary_Border;
    case 'Master':
      return master_Border;
    default:
      return '';
  }
}

function ResizeFont(text, defaultSize, characterLimit) {
  let fontSize = defaultSize;
  if (text.length >= characterLimit) {
    var sizePerCharacter = (defaultSize / characterLimit) / 2
    fontSize = fontSize - (text.length - characterLimit) * sizePerCharacter
  } else {
    fontSize = defaultSize
  }

  return fontSize
}


export default CardTemplate;