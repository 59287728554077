import axios from "axios";
import { host } from "./base";
import { defaultHeaders } from "./base";

export async function checkWalletAlreadyConnected(walletAddress) {
  const resp = await axios.get(
    `${host}/api/v1/auth/wallet/hasConnected?walletAddress=${walletAddress}`,
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function issueSignupNonce(walletAddress) {
  const resp = await axios.post(
    `${host}/api/v1/auth/signup/nonce/issue`,
    { walletAddress: walletAddress },
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function signup(walletAddress, signature) {
  const resp = await axios.post(
    `${host}/api/v1/auth/signup/wallet`,
    {
      walletAddress: walletAddress,
      signedMessage: signature,
    },
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function issueLoginNonce(walletAddress) {
  const resp = await axios.post(
    `${host}/api/v1/auth/login/nonce/issue`,
    { walletAddress: walletAddress },
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function login(walletAddress, signature) {
  const resp = await axios.post(
    `${host}/api/v1/auth/login/wallet`,
    {
      walletAddress: walletAddress,
      signedMessage: signature,
    },
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}

export async function getConnectedWallet() {
  const resp = await axios.get(
    `${host}/api/v1/auth/me/connectedWallet`,
    { headers: { ...defaultHeaders() } },
  );
  return resp;
}