import { ethers } from "ethers";

export function capitalize(s) {
    if (s.length === 0) return s;
    const a = [s.charAt(0).toUpperCase()];
    if (s.length > 1) {
        a.push(s.substring(1));
    }
    return a.join("");
}

export function truncateString(str, n) {
    if (str.length > n) {
        return str.substring(0, n) + "...";
    } else {
        return str;
    }
}

export function truncateStringMiddle(str, n) {
    if (str.length > n * 2) {
        return str.substring(0, n) + "..." + str.substring(str.length - n);
    } else {
        return str;
    }
}

export function storeSessionToken(token) {
    localStorage.setItem("session-token", token);
}
export function readSessionToken() {
    return localStorage.getItem("session-token");
}

/**
 * 
 * @param {Date} t 
 */
export function timeSinceInMs(t) {
    const now = new Date();
    return now - t;
}

export function now() {
    return new Date();
}

export function isMetamaskErrorUserRejected(err) {
    if (err && err.code &&
        (err.code === 4001 || err.code === "ACTION_REJECTED")) {
        console.warn("metamask user rejected");
        return true;
    }
    return false;
}

export const formatNumber = (wei, maximumFractionDigits = 4) => {
    return Intl.NumberFormat("en-US", {
        // minimumFractionDigits: 4,
        maximumFractionDigits: maximumFractionDigits,
    },
    ).format(ethers.utils.formatEther(wei));
}

export const replaceURLToInfuraGateway = (url) => {
    // imageURL = strings.ReplaceAll(imageURL, "bitkubipfs.io", "weaponmastery.infura-ipfs.io")
    if (url.includes("bitkubipfs.io")) {
        return url.replace("bitkubipfs.io", "weaponmastery.infura-ipfs.io");
    }
    return url;
}