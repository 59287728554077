import { ethers } from "ethers";
import { ContractAddressBook } from "../constants";
import CallHelper from "./abi/CallHelper.json";
import WPMSGoldToken from "./abi/WPMSGoldToken.json";
import WPMSWcoinToken from "./abi/WPMSWcoinToken.json";
import WPMSWeaponMastery from "./abi/WPMSWeaponMastery.json";
import WPMSWeaponNFT from "./abi/WPMSWeaponNFT.json";
import WPMSWeaponFarm from "./abi/WPMSWeaponFarm.json";
import WPMSVendor from "./abi/WPMSVendor.json";
import MorningMoonRouter from "./abi/MorningMoonRouter.json";
import KKUB from "./abi/KKUB.json";
import { currentChain } from "../wpms/web3/chain";
import { getReadOnlyJSONRPCProvider } from "../wpms/web3/metaMask";

let contractWrapperInstance = null;

export function GetContractWrapper() {
  if (contractWrapperInstance == null) {
    console.log("..... should call once...");
    const provider = window.ethereum ?
      new ethers.providers.Web3Provider(window.ethereum) :
      getReadOnlyJSONRPCProvider(currentChain());
    contractWrapperInstance = new ContractWrapper(provider);
  }
  return contractWrapperInstance;
}

export class ContractWrapper {
  constructor(provider = null) {
    if (!provider) {
      if (!window.ethereum) {
        provider = getReadOnlyJSONRPCProvider(currentChain());
      } else {
        provider = new ethers.providers.Web3Provider(window.ethereum);
      }
    }
    this._provider = provider;
    this._goldTokenCtr = new ethers.Contract(ContractAddressBook.WPMSGoldToken, WPMSGoldToken, provider);
    this._kkubTokenCtr = new ethers.Contract(ContractAddressBook.KKUBtoken, KKUB, provider);
    this._wcoinTokenCtr = new ethers.Contract(ContractAddressBook.WPMSWcoinToken, WPMSWcoinToken, provider);
    this._callHelperCtr = new ethers.Contract(ContractAddressBook.CallHelper, CallHelper, provider);
    this._weaponMasteryCtr = new ethers.Contract(ContractAddressBook.WPMSWeaponMastery, WPMSWeaponMastery, provider);
    this._weaponNFTCtr = new ethers.Contract(ContractAddressBook.WPMSWeaponNFT, WPMSWeaponNFT, provider);
    this._vendorCtr = new ethers.Contract(ContractAddressBook.WPMSVendor, WPMSVendor, provider);
  }

  async getCurrentBlock() {
    const blockNumber = await this._provider.getBlockNumber();
    return blockNumber;
  }

  async getGoldBalance(walletAddress) {
    const balance = await this._goldTokenCtr.balanceOf(ethers.utils.getAddress(walletAddress));
    return balance;
  }

  async getKKUBBalance(walletAddress) {
    const balance = await this._kkubTokenCtr.balanceOf(ethers.utils.getAddress(walletAddress));
    return balance;
  }

  async getWcoinBalance(walletAddress) {
    const balance = await this._wcoinTokenCtr.balanceOf(ethers.utils.getAddress(walletAddress));
    return balance;
  }

  async getAllWeaponTokens(walletAddress) {
    const tokenIDs = await this._weaponNFTCtr.tokenOfOwnerAll(ethers.utils.getAddress(walletAddress))
    return tokenIDs;
  }

  /**
   * 
   * @param {string} tokenID 
   * @returns wallet address who own the given weapon (tokenID)
   */
  async weaponOwnerOf(tokenID) {
    const owner = await this._weaponNFTCtr.ownerOf(ethers.BigNumber.from(tokenID));
    return owner;
  }

  async getWeaponTokenURI(tokenID) {
    const uri = await this._weaponNFTCtr.tokenURI(tokenID);
    return uri;
  }

  async getWeaponStakingPower(tokenID) {
    const power = await this._weaponNFTCtr.stakingPower(tokenID);
    return power;
  }

  async getGoldTokenAllowanceForMastery(walletAddress) {
    console.log("get allowance of", walletAddress, "for", ContractAddressBook.WPMSWeaponMastery);
    const allowance = await this._goldTokenCtr.allowance(ethers.utils.getAddress(walletAddress), ethers.utils.getAddress(ContractAddressBook.WPMSWeaponMastery));
    return allowance;
  }

  async approveGoldTokenToMastery(amountInWei) {
    const signer = this._provider.getSigner();
    const tx = await this._goldTokenCtr.connect(signer).approve(ContractAddressBook.WPMSWeaponMastery, amountInWei);
    const recipt = await tx.wait();
    console.log("approveGoldTokenToMastery receipt status:", recipt.status, "txHash:", recipt.transactionHash)
  }

  async getWcoinTokenAllowanceForMastery(walletAddress) {
    console.log("get allowance of", walletAddress, "for", ContractAddressBook.WPMSWeaponMastery);
    const allowance = await this._wcoinTokenCtr.allowance(ethers.utils.getAddress(walletAddress), ethers.utils.getAddress(ContractAddressBook.WPMSWeaponMastery));
    return allowance;
  }

  async approveWcoinTokenToMastery(amountInWei) {
    const signer = this._provider.getSigner();
    const tx = await this._wcoinTokenCtr.connect(signer).approve(ContractAddressBook.WPMSWeaponMastery, amountInWei);
    const recipt = await tx.wait();
    console.log("approveWcoinTokenToMastery receipt status:", recipt.status, "txHash:", recipt.transactionHash)
  }

  async getKKUBTokenAllowanceForMastery(walletAddress) {
    console.log("get KKUB allowance of", walletAddress, "for", ContractAddressBook.WPMSWeaponMastery);
    const allowance = await this._kkubTokenCtr.allowance(ethers.utils.getAddress(walletAddress), ethers.utils.getAddress(ContractAddressBook.WPMSWeaponMastery));
    console.log("get KKUB allowance of", walletAddress, "for", ContractAddressBook.WPMSWeaponMastery, "allowance:", allowance.toString());

    return allowance;
  }

  async approveKKUBTokenToMastery(amountInWei) {
    const signer = this._provider.getSigner();
    const tx = await this._kkubTokenCtr.connect(signer).approve(ContractAddressBook.WPMSWeaponMastery, amountInWei);
    const recipt = await tx.wait();
    console.log("approveKKUBTokenToMastery receipt status:", recipt.status, "txHash:", recipt.transactionHash)
  }

  async approveAllWeaponNFTsToAddress(spenderAddress) {
    const signer = this._provider.getSigner();
    const tx = await this._weaponNFTCtr.connect(signer).setApprovalForAll(spenderAddress, true);
    const recipt = await tx.wait();
    console.log("setApproveForAll for spender:", spenderAddress, "txHash:", recipt.transactionHash)
  }
  async isAllWeaponNFTsApprovedForAddress(walletAddress, spenderAddress) {
    const approved = await this._weaponNFTCtr.isApprovedForAll(ethers.utils.getAddress(walletAddress), ethers.utils.getAddress(spenderAddress));
    return approved;
  }

  //#region Farm 

  farmContract(addr) {
    return new ethers.Contract(addr, WPMSWeaponFarm, this._provider);
  }

  //#region Read

  async getFarmStartBlock(farmAddress) {
    const farm = this.farmContract(farmAddress);
    const b = await farm.startBlock();
    return b;
  }
  async getFarmBonusEndBlock(farmAddress) {
    const farm = this.farmContract(farmAddress);
    const b = await farm.bonusEndBlock();
    return b;
  }
  // getFarmPendingReward return pending reward of the given wallet address
  // which represent WCOIN earned not not claimed yet
  async getFarmPendingReward(farmAddress, walletAddress) {
    const farm = this.farmContract(farmAddress);
    const pending = await farm.pendingReward(ethers.utils.getAddress(walletAddress));
    return pending;
  }
  // getFarmRewardPerBlock return reward per block
  // which represent mining rate XX WCOIN per day. you can derive reward per day by multiplying with estimated number of blocks per days
  async getFarmRewardPerBlock(farmAddress) {
    const farm = this.farmContract(farmAddress);
    const rewardPerBlock = await farm.rewardPerBlock();
    return rewardPerBlock;
  }
  // getFarmTotalReward return total mining reward
  async getFarmTotalReward(farmAddress) {
    const rewardPerBlock = await this.getFarmRewardPerBlock(farmAddress);
    const startBlock = await this.getFarmStartBlock(farmAddress);
    const bonusEndBlock = await this.getFarmBonusEndBlock(farmAddress);
    return bonusEndBlock.sub(startBlock).mul(rewardPerBlock);
  }
  // getFarmTotalStakingAmount return totalMiningPower
  async getFarmTotalStakingAmount(farmAddress) {
    const farm = this.farmContract(farmAddress);
    const totalStakingAmount = await farm.totalStakingAmount();
    return totalStakingAmount;
  }
  async getFarmUserStakingValue(farmAddress, walletAddress) {
    const farm = this.farmContract(farmAddress);
    const userInfo = await farm.userInfo(walletAddress);
    console.log("userInfo:", userInfo);
    return userInfo.amount;
  }
  async getStakingNftIDsOfUser(farmAddress, walletAddress) {
    const farm = this.farmContract(farmAddress);
    const nftIDs = await farm.getStakingNftIDsOf(walletAddress);
    return nftIDs;
  }

  //#endregion

  //#region Write

  async depositNFTToFarm(farmAddress, nftIDs = []) {
    const signer = this._provider.getSigner();
    const farm = new ethers.Contract(farmAddress, WPMSWeaponFarm, this._provider);
    const tx = await farm.connect(signer).deposit(nftIDs);
    const recipt = await tx.wait();
  }
  async harvestFromFarm(farmAddress) {
    const signer = this._provider.getSigner();
    const farm = new ethers.Contract(farmAddress, WPMSWeaponFarm, this._provider);
    const tx = await farm.connect(signer).harvest();
    const recipt = await tx.wait();
  }
  async withdrawNFTFromFarm(farmAddress, nftIDs = []) {
    const signer = this._provider.getSigner();
    const farm = new ethers.Contract(farmAddress, WPMSWeaponFarm, this._provider);
    const tx = await farm.connect(signer).withdraw(nftIDs);
    const recipt = await tx.wait();
  }

  //#endregion

  //#endregion

  //#region Vendor


  async getWcoinTokenAllowanceForVendor(walletAddress) {
    console.log("get allowance of", walletAddress, "for", ContractAddressBook.WPMSVendor);
    const allowance = await this._wcoinTokenCtr.allowance(ethers.utils.getAddress(walletAddress), ethers.utils.getAddress(ContractAddressBook.WPMSVendor));
    return allowance;
  }

  async approveWcoinTokenToVendor(amountInWei) {
    const signer = this._provider.getSigner();
    const tx = await this._wcoinTokenCtr.connect(signer).approve(ContractAddressBook.WPMSVendor, amountInWei);
    const recipt = await tx.wait();
    console.log("approveWcoinTokenToVendor receipt status:", recipt.status, "txHash:", recipt.transactionHash)
  }

  async vendorAmountOut(inAmount) {
    const amountOut = await this._vendorCtr.amountOut(inAmount);
    return amountOut;
  }

  async vendorSellWCoin(amount) {
    const signer = this._provider.getSigner();
    const tx = await this._vendorCtr.connect(signer).sellWCoin(amount);
    const recipt = await tx.wait();
    console.log("sellWCoin:", amount, "txHash:", recipt.transactionHash)
  }

  //#endregion

  morningMoonRouterContract(addr) {
    return new ethers.Contract(addr, MorningMoonRouter, this._provider);
  }

  async getKUBPrinceInKUSDT() {
    const router = this.morningMoonRouterContract(ContractAddressBook.MorningMoonRouter);
    const price = await router.getAmountsOut(ethers.utils.parseEther("1"), [ContractAddressBook.KKUBtoken, ContractAddressBook.KUSDTToken]);
    return price[1];
  }
}