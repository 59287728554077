import React, { useEffect, useRef } from "react";
import MiniGameSlot from "./components/minigameslot";

function MiniGameTest() {
  const refMiniGame = useRef();

  useEffect(() => {
    if (refMiniGame.current) {
      refMiniGame.current.resetGame();
    }
  }, [refMiniGame]);
  return (
    <MiniGameSlot ref={refMiniGame} />
  );
}

export default MiniGameTest;