import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import * as constants from "../../constants";
import { formatNumber, readSessionToken, storeSessionToken, truncateStringMiddle } from "../../utilities";

import { AuthContext } from "@ryanar/react-auth-provider";
import { ethers } from "ethers";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ContractWrapper, GetContractWrapper } from "../../contractwrapper/contractWrapper";
import { checkWalletAlreadyConnected, getConnectedWallet, issueLoginNonce, issueSignupNonce, login, signup } from "../api/auth";
import { currentChain } from "../web3/chain";
import { getReadOnlyJSONRPCProvider, isBKCTestnetNetwork, isConnectToCurrentChain, switchToCurrentChain } from "../web3/metaMask";
import { ConnectModal, ConnectModalResult } from "./connectModal";
import { ConnectWalletContext } from "./ConnectWalletContext";
import { MDBCollapse, MDBIcon, MDBNavbarItem, MDBNavbarNav, MDBNavbarToggler } from "mdb-react-ui-kit";

export function Navbar(props) {
  const { activate, account, library } = useWeb3React();
  const { authenticated, setAuthenticated } = React.useContext(AuthContext);
  const [_, setHasActivated] = useState(false);
  const [initializeStep, setInitializeStep] = useState(101);
  const [connectWalletStep, setConnectWalletStep] = useState(0);
  const [wcoinPriceInKUSDTString, setWcoinPriceInKUSDTString] = useState("");
  const [showConnectModal, setShowConnectModal] = useState(false);
  const refInitializeData = useRef({
    connectedWalletAddress: "",
  });
  const [showNavNoToggler, setShowNavNoToggler] = useState(false);

  const injected = new InjectedConnector({
    supportedChainIds: [
      constants.BitkubChainTestnetRPCInfo.networkID,
      constants.BitkubChainMainnetRPCInfo.networkID,
    ],
  });

  useEffect(() => {
    updateWCoinPriceInKUSDT();

    if (window.ethereum) {
      // detect Metamask account change
      window.ethereum.on('accountsChanged', function (accounts) {
        storeSessionToken("");
        setAuthenticated(false);
        setInitializeStep(101);
      });

      // detect Network account change
      window.ethereum.on('networkChanged', function (networkId) {
        const c = currentChain();
        if (networkId.toString() !== c.chainIdAsInt.toString()) {
          alert(`please switch to ${c.chainName}`);
          switchToCurrentChain(window);
        }
      });
    }
  }, []);

  useEffect(() => {
    const logTag = `[initizlizeStep::${initializeStep}]`;
    // check existing session token
    const savedSessionToken = readSessionToken();
    if (!savedSessionToken) {
      console.log(logTag, "no saved session token");
    }

    console.log(logTag, "with sessionToken:", savedSessionToken);
    (async () => {
      switch (initializeStep) {
        case 101:
          try {
            const resp = await getConnectedWallet();
            if (!resp.data.success) return;
            const { walletAddress } = resp.data.data;
            refInitializeData.current = {
              ...refInitializeData.current,
              connectedWalletAddress: walletAddress,
            };
            setInitializeStep(201);
          } catch (err) {
            console.warn("[initializeStep::101] - got error: ", err);
            setInitializeStep(901);
          }
          break;
        case 201:
          try {
            await activate(injected);
            console.log(logTag, "activated... then check connected RPC network");
            if (!(await isConnectToCurrentChain(window))) {
              const c = currentChain();
              console.warn("does not connect to current chain:", c.chainName);
              setInitializeStep(901);
              return;
            }
            setInitializeStep(301);
          } catch (err) {
            setInitializeStep(901);
            return;
          }
          break;
        case 301:
          if (account.toLowerCase() === refInitializeData.current.connectedWalletAddress.toLowerCase()) {
            setAuthenticated(true);
            setInitializeStep(401);
          } else {
            setInitializeStep(901);
          }
          break;
        case 801:
          console.log(logTag, "done with success");
          props.hasAuthenTrigger();
          break;
        case 901:
          console.log(logTag, "done with failure, user need to connect wallet");
          break;
      }
    })();
  }, [initializeStep]);

  useEffect(() => {
    if (connectWalletStep === 0) return;
    if (!account) return;

    (async () => {
      const walletAddress = account;
      const resp = await checkWalletAlreadyConnected(walletAddress);
      const { connected } = resp.data.data;

      // signup ...
      if (!connected) {
        const issueSignupNonceResp = await issueSignupNonce(walletAddress);
        const {
          messageToBeSigned,
          nonce, // for debug...
        } = issueSignupNonceResp.data.data;
        const signer = library.getSigner();
        const sig = await signer.signMessage(messageToBeSigned);
        const signupResp = await signup(walletAddress, sig);
      }

      // login ...
      if (!authenticated) {
        const issueLoginNonceResp = await issueLoginNonce(walletAddress);
        const {
          messageToBeSigned,
          nonce, // for debug...
        } = issueLoginNonceResp.data.data;
        const signer = library.getSigner();
        const sig = await signer.signMessage(messageToBeSigned);

        const loginResp = await login(walletAddress, sig);
        const { sessionToken } = loginResp.data.data;
        setAuthenticated(true);
        storeSessionToken(sessionToken);

        props.hasAuthenTrigger();
      }

      setConnectWalletStep(0);
    })();
  }, [account, connectWalletStep]);

  const onConnectWalletClicked = async () => {
    setShowConnectModal(true);
  };
  const onUserButtonClicked = async () => {
    setShowConnectModal(true);
  };

  const connectWallet = async () => {
    try {

      await switchToCurrentChain(window);
      await activate(injected);
    } catch (err) {
      console.error("failed to activate:", err);
    }
    setHasActivated(true);
    setConnectWalletStep(101);
  };

  const updateWCoinPriceInKUSDT = async () => {
    const contractWrapper = GetContractWrapper();
    const kkubPriceInKUSDT = await contractWrapper.getKUBPrinceInKUSDT();
    const wcoinPriceInKKUB = await contractWrapper.vendorAmountOut(ethers.utils.parseEther("1"));
    const wcoinPriceInKUSDT = ethers.BigNumber.from(wcoinPriceInKKUB).
      mul(kkubPriceInKUSDT).
      div(ethers.utils.parseEther("1"));
    setWcoinPriceInKUSDTString(formatNumber(wcoinPriceInKUSDT, 6));
  }

  useContext(ConnectWalletContext).refConnectWalletFunc.current = connectWallet;

  return (<>
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">

        <a className="navbar-brand mt-2 mt-lg-0" href="./#about">
          <img src="/wpms/images/logo.png" height="64" alt="Logo" loading="lazy" />
          <small>Weapon Mastery</small>
        </a>

        <MDBNavbarToggler
          type='button'
          data-target='#navbarTogglerDemo01'
          aria-controls='navbarTogglerDemo01'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNavNoToggler(!showNavNoToggler)}>
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNavNoToggler}>

          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item active">
              <a className="nav-link" aria-current="page" href="./#create">CREATE</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./#combine">COMBINE</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./#mining">MINING</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./#leaderboard">LEADERBOARD</a>
            </li>
          </ul>

          <div className="d-flex align-items-center">
            <a className="btn btn-link px-3 me-2" href="./#wcoin" role="button" rel="nofollow" style={{ "fontSize": "14px" }}><i
              className="fa fa-coins me-2"></i>WCOIN ${wcoinPriceInKUSDTString}</a>
            <a className="btn btn-primary px-3 me-2" href="https://twitter.com/WeaponMasteryTH" role="button" target="_blank"><i
              className="fab fa-twitter"></i></a>
            <a className="btn btn-primary px-3 me-2" href="https://www.facebook.com/WeaponMasteryNFT" role="button" target="_blank"><i
              className="fab fa-facebook"></i></a>
            <div className="dropdown">
              {
                !authenticated ?
                  <button className="btn btn-primary me-3" type="button" id="dropdownMenuButton"
                    data-mdb-toggle="dropdown" aria-expanded="false"
                    onClick={onConnectWalletClicked}>
                    {initializeStep < 801 ? "Initialzing..." : "CONNECT WALLET"}
                  </button> : <UserButton account={account}
                    onClick={onUserButtonClicked} />
              }
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a className="dropdown-item">Metamask</a></li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li><a className="dropdown-item small" href="#">How to transfer NFT from<br />Bitkub NEXT to Metamask</a></li>
              </ul>
            </div>
          </div>
        </MDBCollapse>

        {/* <button className="navbar-toggler collapsed" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbar01"
          aria-controls="navbar01" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button> */}

        {/* <div className="navbar-collapse collapse" id="navbar01">

          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item active">
              <a className="nav-link" aria-current="page" href="./#create">CREATE</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./#combine">COMBINE</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./#mining">MINING</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./#leaderboard">LEADERBOARD</a>
            </li>
          </ul>

          <div className="d-flex align-items-center">
            <a className="btn btn-link px-3 me-2" href="./#wcoin" role="button" rel="nofollow" style={{ "fontSize": "14px" }}><i
              className="fa fa-coins me-2"></i>WCOIN ${wcoinPriceInKUSDTString}</a>
            <a className="btn btn-primary px-3 me-2" href="https://twitter.com/WeaponMasteryTH" role="button" target="_blank"><i
              className="fab fa-twitter"></i></a>
            <a className="btn btn-primary px-3 me-2" href="https://www.facebook.com/WeaponMasteryNFT" role="button" target="_blank"><i
              className="fab fa-facebook"></i></a>
            <div className="dropdown">
              {
                !authenticated ?
                  <button className="btn btn-primary me-3" type="button" id="dropdownMenuButton"
                    data-mdb-toggle="dropdown" aria-expanded="false"
                    onClick={onConnectWalletClicked}>
                    {initializeStep < 801 ? "Initialzing..." : "CONNECT WALLET"}
                  </button> : <UserButton account={account}
                    onClick={onUserButtonClicked} />
              }
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a className="dropdown-item">Metamask</a></li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li><a className="dropdown-item small" href="#">How to transfer NFT from<br />Bitkub NEXT to Metamask</a></li>
              </ul>
            </div>
          </div>

        </div> */}
      </div>
      <ConnectModal show={showConnectModal} setShow={setShowConnectModal} onResult={(result) => {
        switch (result) {
          case ConnectModalResult.ConnectMetamask:
            connectWallet();
            break;
          case ConnectModalResult.HowToTransferNFT:
            //TODO: nenin - ...
            break;
          case ConnectModalResult.MyInventory:
            break;
          case ConnectModalResult.Disconnect:
            storeSessionToken("");
            setAuthenticated(false);
            setInitializeStep(101);
            break;
        }
      }} />
    </nav>
  </>
  );
}

function UserButton(props) {
  const { account, onClick } = props;
  return < div className="dropdown" >
    <button
      className="btn btn-primary me-3 dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-mdb-toggle="dropdown"
      aria-expanded="false"
      onClick={onClick}
    >
      <i className="fa fa-user me-2"></i> {`${truncateStringMiddle(account, 4)}`}
    </button>
  </div >;
}