import { readSessionToken } from "../../utilities";

export const host = process.env.REACT_APP_API_SERVER_HOSTNAME ?
  process.env.REACT_APP_API_SERVER_HOSTNAME :
  "http://139.180.143.152:9010";

export const defaultHeaders = () => {
  let h = { "x-game-client-version": "1.0" };
  const token = readSessionToken();
  if (token) {
    h = { ...h, "x-session-token": token };
  }
  return h;
}