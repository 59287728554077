export const roadmapComponent = () => (
  <div className="card">
    <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
      <img src="/wpms/images/roadmap2.jpg" className="img-fluid" />
      <a href="#!">
        <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
      </a>
    </div>
    <div className="card-body">
      <h5 className="card-title">Roadmap</h5>
      <p className="card-text">
        Weapon Mastery is a mining card game, launched in May 2023. A new mining dungeon will be released in
        Q2, Q3, and Q4 with a total of 500,0000 WCOIN to mine. Player can create and combine new weapons
        endlessly as long as the game is still operating.
      </p>
      <a href="#mining" className="btn btn-primary">Mining</a>
    </div>
  </div>
);
