import { AuthContext } from "@ryanar/react-auth-provider";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";


export const ConnectModalResult = {
  UserCancel: 0,
  ConnectMetamask: 1,
  HowToTransferNFT: 2,
  Disconnect: 3,
  MyInventory: 4,
};

export function ConnectModal(props) {
  const { show, setShow, onResult } = props;
  const { authenticated } = React.useContext(AuthContext);
  const toggleModal = async (result) => {
    setShow(!show);
    if (onResult) onResult(result);
  };
  const navigate = useNavigate();

  return <>
    <MDBModal backdrop={false} show={show} setShow={setShow}>
      <MDBModalDialog className='modal-dialog modal-dialog-centered'>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Your Wallet</MDBModalTitle>
            <MDBBtn
              type='button'
              className='btn-close'
              color='none'
              onClick={() => toggleModal(ConnectModalResult.UserCancel)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            {authenticated ?
              <>
                <MDBRow className='text-center my-2 mb-3 mx-3'>
                  <MDBBtn
                    className='btn-lg'
                    type='button'
                    color='light'
                    outline={true}
                    onClick={() => toggleModal(ConnectModalResult.Disconnect)}>
                    Disconnect
                  </MDBBtn>
                </MDBRow>
                <MDBRow className='text-center my-2 mx-3' style={{ marginTop: 5 }}>
                  <MDBBtn
                    className='btn-lg'
                    type='button'
                    color='light'
                    outline={true}
                    onClick={() => {
                      navigate("/inventory");
                      toggleModal(ConnectModalResult.MyInventory);
                    }}>
                    <i className="fa fa-wallet me-2"></i>My Inventory
                  </MDBBtn>
                </MDBRow>
              </> :
              <>
                <MDBRow className='text-center my-2 mb-3 mx-3'>
                  <MDBBtn
                    className='btn-lg'
                    type='button'
                    color='light'
                    outline={true}
                    onClick={() => toggleModal(ConnectModalResult.ConnectMetamask)}>
                    <i className="fa fa-wallet me-2"></i>Metamask
                  </MDBBtn>
                </MDBRow>
                <MDBRow className='text-center my-2 mx-3' style={{ marginTop: 5 }}>
                  <MDBBtn
                    className='btn-lg'
                    type='button'
                    color='light'
                    outline={true}
                    onClick={() => { 
                     window.open("https://www.facebook.com/bitkubchainofficial/posts/pfbid0xcEoEPsMyYTndEe1qevi88S6aSwFjeHmEdAExZdnxtxNtZm7gSJySYFce3ztjVYhl", "_blank") 
                    }}>
                    How to transfer NFT from Bitkub NEXT to Metamask
                  </MDBBtn>
                </MDBRow>
              </>
            }
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn type='button' color='primary' onClick={() => {
              toggleModal();
            }}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  </>
}