const KUB = {
  nativeCurrency: {
    name: "Bitkub",
    symbol: "KUB",
    decimals: 18,
  }
};

export const ChainInfo = {
  BitkubChainMainnet: {
    chainId: "0x60",
    chainIdAsInt: parseInt("0x60", 16),
    rpcUrls: ["https://rpc.bitkubchain.io"],
    chainName: "Bitkubchain",
    nativeCurrency: KUB,
    blockExplorerUrls: ["https://bkcscan.com"],
  },
  BitkubChainTestnet: {
    chainId: "0x6545",
    chainIdAsInt: parseInt("0x6545", 16),
    rpcUrls: ["https://rpc-testnet.bitkubchain.io"],
    chainName: "Bitkubchain Testnet",
    nativeCurrency: KUB,
    blockExplorerUrls: ["https://testnet.bkcscan.com"],
  },
  ForkingBitkubChainTestnet: {
    chainId: "0x6545",
    chainIdAsInt: parseInt("0x6545", 16),
    rpcUrls: ["http://localhost:8545"],
    chainName: "Bitkubchain Testnet",
    nativeCurrency: KUB,
    blockExplorerUrls: ["https://testnet.bkcscan.com"],
  },
};

export const currentChain = () => {
  if (process.env.REACT_APP_CURRENT_CHAIN) {
    const chain = process.env.REACT_APP_CURRENT_CHAIN;
    if (ChainInfo[chain]) {
      return ChainInfo[chain];
    }
    return ChainInfo.BitkubChainTestnet;
  }
  return ChainInfo.BitkubChainTestnet
};
// export const currentChain = () => ChainInfo.ForkingBitkubChainTestnet;