import React, { useState } from 'react';
import "./previewWeaponCard.css";

export function PreviewWeaponCard(props) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div className="bg-image ripple shadow-1-strong rounded" data-mdb-ripple-color="light"
      onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <img src={props.imageURL} className="w-100" alt="Louvre" />
      {isShown && (
        <div className="mask" style={{ "backgroundColor": `hsla(0, 0%, 98%, 0.2)` }}>
          <div className="preview-container">
            <div className="preview-centered-text">
              #{props.no} - {props.playerAddress}
            </div>
          </div>
        </div>
      )}

    </div>
  );
}