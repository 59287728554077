import { AuthContext } from "@ryanar/react-auth-provider";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { ethers } from "ethers";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBSpinner } from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import { blocksPerDay } from "../../constants";
import { ContractWrapper, GetContractWrapper } from "../../contractwrapper/contractWrapper";
import { formatNumber, isMetamaskErrorUserRejected, replaceURLToInfuraGateway } from "../../utilities";
import { currentChain } from "../web3/chain";
import { getReadOnlyJSONRPCProvider } from "../web3/metaMask";


export function DungeonInfoComponent(props) {
  const { authenticated } = React.useContext(AuthContext);
  const { farmName, farmAddress, imageURL, weaponReward, comingSoonText, dungeonStartDate, dungeonEndDate, startBlock, endBlock } = props;
  const contractWrapper = GetContractWrapper();
  const readonlyContractWrapper = GetContractWrapper();
  const { account: walletAddress } = useWeb3React();

  const [hasApproveNFTToFarm, setHasApproveNFTToFarm] = useState(false);
  const [isApprovingNFTToFarmInProgress, setIsApprovingNFTToFarmInProgress] = useState(false);
  const [isTxProcessing, setIsTxProcessing] = useState(false);

  const [farmInfo, setFarmInfo] = useState({
    currentBlock: ethers.BigNumber.from(0),
    startBlock: ethers.BigNumber.from(0),
    bonusEndBlock: ethers.BigNumber.from(0),
    totalReward: ethers.BigNumber.from(0),
    rewardPerBlock: ethers.BigNumber.from(0),
    rewardPerDay: ethers.BigNumber.from(0),
    totalMiningPower: ethers.BigNumber.from(0),
    userMiningPower: ethers.BigNumber.from(0),
    pendingReward: ethers.BigNumber.from(0),
  });

  const [showDepositWeaponSelectionModal, setShowDepositWeaponSelectionModal] = useState(false);
  const [showWithdrawWeaponSelectionModal, setShowWithdrawWeaponSelectionModal] = useState(false);
  const [showConfirmToHarvestModal, setShowConfirmToHarvestModal] = useState(false);

  useEffect(() => {
    if (!authenticated || !walletAddress) return;
    (async () => {
      const approved = await readonlyContractWrapper.isAllWeaponNFTsApprovedForAddress(walletAddress, farmAddress);
      setHasApproveNFTToFarm(approved);
    })();
  }, [authenticated, walletAddress]);

  const updateFarmInfo = async () => {
    const currentBlock = await readonlyContractWrapper.getCurrentBlock();
    const startBlock = await readonlyContractWrapper.getFarmStartBlock(farmAddress);
    const bonusEndBlock = await readonlyContractWrapper.getFarmBonusEndBlock(farmAddress);
    const totalReward = await readonlyContractWrapper.getFarmTotalReward(farmAddress);
    let rewardPerBlock = await readonlyContractWrapper.getFarmRewardPerBlock(farmAddress);
    if (currentBlock > bonusEndBlock) rewardPerBlock = ethers.BigNumber.from(0);
    const rewardPerDay = rewardPerBlock.mul(ethers.BigNumber.from(blocksPerDay));
    const totalMiningPower = await readonlyContractWrapper.getFarmTotalStakingAmount(farmAddress);

    let farmInfo = {
      currentBlock,
      startBlock,
      bonusEndBlock,
      totalReward,
      rewardPerBlock,
      rewardPerDay,
      totalMiningPower,
      userMiningPower: ethers.BigNumber.from(0),
      pendingReward: ethers.BigNumber.from(0),
    };

    if (authenticated && walletAddress && hasApproveNFTToFarm) {
      const userMiningPower = await readonlyContractWrapper.getFarmUserStakingValue(farmAddress, walletAddress);
      const pendingReward = await readonlyContractWrapper.getFarmPendingReward(farmAddress, walletAddress);
      farmInfo = {
        ...farmInfo,
        userMiningPower,
        pendingReward
      };
    }

    setFarmInfo(farmInfo);
  };

  let didInit = false;
  useEffect(() => {
    if (didInit) return;

    didInit = true;
    updateFarmInfo();
  }, [authenticated, walletAddress, hasApproveNFTToFarm]);

  const approveNFT = async () => {
    let success = false;
    try {
      setIsApprovingNFTToFarmInProgress(true);
      await contractWrapper.approveAllWeaponNFTsToAddress(farmAddress);
      success = true;
    } catch (err) {
      console.warn("approveNFT error:", err);
    } finally {
      setIsApprovingNFTToFarmInProgress(false);
    }

    if (!success) return;
    setHasApproveNFTToFarm(true);
  };
  const beginDeposit = async () => {
    setShowDepositWeaponSelectionModal(true);
  };
  const beginWithdraw = async () => {
    setShowWithdrawWeaponSelectionModal(true);
  };
  const beginHarvest = async () => {
    setShowConfirmToHarvestModal(true);
  };

  const spinner = () =>
    <MDBSpinner className='mx-2' color='secondary' size="sm">
      <span className='visually-hidden'>Loading...</span>
    </MDBSpinner>;

  return (
    <div className="card">
      <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
        <img src={imageURL} className="img-fluid" />
        <a href="#!">
          <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
        </a>
      </div>
      <div className="card-body">
        <h5 className="card-title">{farmName}
          {
            farmInfo.currentBlock < farmInfo.startBlock ?
              <span className="badge rounded-pill bg-warning text-dark" style={{ "marginLeft": "5px" }}>coming soon</span> :
              farmInfo.currentBlock > farmInfo.bonusEndBlock ?
                <span className="badge rounded-pill bg-danger text-white" style={{ "marginLeft": "5px" }}>ended</span> :
                <span className="badge rounded-pill bg-success text-dark" style={{ "marginLeft": "5px" }}>active</span>
          }
        </h5>
        {
          <>
            {
              dungeonStartDate && dungeonEndDate ? <small>Mining Period (estimate): {`${dungeonStartDate} - ${dungeonEndDate}`}<br></br></small> : null
            }

            <small>Mining Blocks: #{startBlock} - #{endBlock}</small>
          </>
        }
        <hr />
        <p className="card-text">
          <strong>Total Mining Reward: {`${formatNumber(farmInfo.totalReward)}`} WCOIN</strong><br />
          Mining Rate: {farmInfo.totalMiningPower.gt(0) ? formatNumber((farmInfo.rewardPerDay.mul(ethers.utils.parseEther("1.0")).div(farmInfo.totalMiningPower)).mul(100)) : "0"} WCOIN/100 Power/Day<br />
          Top 10 Weapons Reward:<br />
          <button type="button" className={`btn ${weaponReward.buttonStyle} my-2`}>
            {weaponReward.info}
          </button>
        </p>
        <p className="card-text">
          Total Mining Power: {formatNumber(farmInfo.totalMiningPower)}<br />
          {authenticated && hasApproveNFTToFarm ? <>{`Your Mining Power: ${formatNumber(farmInfo.userMiningPower)}`}<br /></> : null}
          {authenticated && hasApproveNFTToFarm ? <>{`Your Mining Rate: ${farmInfo.userMiningPower.gt(0) ? formatNumber(farmInfo.rewardPerDay.mul(farmInfo.userMiningPower).div(farmInfo.totalMiningPower)) : "0"} WCOIN/Day`}<br /></> : null}
          {authenticated && hasApproveNFTToFarm ? <>{`WCOIN Earned: ${formatNumber(farmInfo.pendingReward)}`}</> : null}
        </p>

        {
          authenticated ? (() => {
            if (isTxProcessing) {
              return <a href="#!" className={`btn btn-primary btn-block my-2 disabled`} onClick={beginDeposit}>{spinner()} Processing Tx</a>
            }

            if (hasApproveNFTToFarm) {
              return <>
                {
                  // do not show deposit button when farm ends rewarding
                  (farmInfo.currentBlock <= farmInfo.bonusEndBlock) ?
                    <a href="#!" className={`btn btn-primary btn-block my-2`} onClick={beginDeposit}>Mine with your weapons</a> :
                    null
                }
                <a href="#!" className={`btn btn-outline-warning text-white  btn-block my-2`} onClick={beginWithdraw}>Withdraw</a>
                {
                  (farmInfo.currentBlock >= farmInfo.startBlock) ?
                    <a href="#!" className={`btn btn-success btn-block my-2`} onClick={beginHarvest}>Harvest</a> :
                    null
                }
              </>;
            } else {
              return <>
                <a href="#!" className={`btn btn-primary btn-block my-2 ${isApprovingNFTToFarmInProgress ? "disabled" : ""}`} onClick={approveNFT}>{isApprovingNFTToFarmInProgress ? "Approving in progress" : "Approve Weapon for Dungeon"}</a>
              </>
            }
          })() : null
        }

        <SelectWeaponModal
          purpose={SelectWeaponPurpose.DEPOSIT}
          walletAddress={walletAddress}
          farmAddress={farmAddress}
          showWeaponSelectionModal={showDepositWeaponSelectionModal}
          setShowWeaponSelectionModal={setShowDepositWeaponSelectionModal}
          onDepositConfirmed={async (nftIDs) => {
            try {
              setIsTxProcessing(true);
              await contractWrapper.depositNFTToFarm(farmAddress, nftIDs);
              await updateFarmInfo();
            } catch (err) {
              if (!isMetamaskErrorUserRejected(err)) {
                console.error(err);
              }
            } finally {
              setIsTxProcessing(false);
            }
          }}
        />
        <SelectWeaponModal
          purpose={SelectWeaponPurpose.WITHDRAW}
          walletAddress={walletAddress}
          farmAddress={farmAddress}
          showWeaponSelectionModal={showWithdrawWeaponSelectionModal}
          setShowWeaponSelectionModal={setShowWithdrawWeaponSelectionModal}
          onWithdrawConfirmed={async (nftIDs) => {
            try {
              setIsTxProcessing(true);
              await contractWrapper.withdrawNFTFromFarm(farmAddress, nftIDs);
              await updateFarmInfo();
            } catch (err) {
              if (!isMetamaskErrorUserRejected(err)) {
                console.error(err);
              }
            } finally {
              setIsTxProcessing(false);
            }
          }}
        />
        <ConfirmToHarvestModal
          authenticated={authenticated}
          walletAddress={walletAddress}
          farmAddress={farmAddress}
          showConfirmToHarvestModal={showConfirmToHarvestModal}
          setShowConfirmToHarvestModal={setShowConfirmToHarvestModal}
          onHarvestConfirmed={async () => {
            try {
              setIsTxProcessing(true);
              await contractWrapper.harvestFromFarm(farmAddress);
              await updateFarmInfo();
            } catch (err) {
              if (!isMetamaskErrorUserRejected(err)) {
                console.error(err);
              }
            } finally {
              setIsTxProcessing(false);
            }
          }} />
      </div>
    </div>
  );
}

function ConfirmToHarvestModal({
  authenticated,
  walletAddress,
  farmAddress,
  showConfirmToHarvestModal,
  setShowConfirmToHarvestModal,
  onHarvestConfirmed }) {

  const toggleModal = async () => setShowConfirmToHarvestModal(!showConfirmToHarvestModal);
  const [harvestInfo, setHarvestInfo] = useState({ fetching: true, pendingReward: ethers.BigNumber.from(0) });
  const readonlyContractWrapper = GetContractWrapper();

  useEffect(() => {
    console.log("Wallet Address: " + walletAddress + ", FarmAddress:" + farmAddress)
    if (!authenticated) return;
    if (!walletAddress || !farmAddress) return;
  }, [walletAddress, farmAddress]);

  useEffect(() => {
    if (!showConfirmToHarvestModal) return;
    (async () => {
      const pendingReward = await readonlyContractWrapper.getFarmPendingReward(farmAddress, walletAddress);
      setHarvestInfo({ fetching: false, pendingReward });
    })();
  }, [showConfirmToHarvestModal])

  return (
    <MDBModal tabIndex='-1' show={showConfirmToHarvestModal} setShow={setShowConfirmToHarvestModal}>
      <MDBModalDialog className='modal-dialog modal-dialog-centered'>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Harvest WCOIN</MDBModalTitle>
            <MDBBtn
              type='button'
              className='btn-close'
              color='none'
              onClick={toggleModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            {harvestInfo.fetching ? "Loading..." : `Harvest ${formatNumber(harvestInfo.pendingReward)} WCOIN now?`}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn type='button' color='secondary' onClick={() => {
              toggleModal();
              if (onHarvestConfirmed) onHarvestConfirmed();
            }}>
              Harvest
            </MDBBtn>
            <MDBBtn type='button' color='primary' onClick={() => {
              toggleModal();
            }}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

const SelectWeaponPurpose = {
  DEPOSIT: 0,
  WITHDRAW: 1,
};

/**
 * 
 * @param {string} walletAddress
 * @param {SelectWeaponPurpose} purpose
 * @param {boolean} showWeaponSelectionModal
 * @param {function} setShowWeaponSelectionModal
 * @param {function} onDepositConfirmed
 * @param {function} onWithdrawConfirmed
 */
function SelectWeaponModal({
  farmAddress,
  walletAddress, purpose,
  showWeaponSelectionModal, setShowWeaponSelectionModal,
  onDepositConfirmed,
  onWithdrawConfirmed,
}) {
  const [selectedWeapons, setSelectedWeapons] = useState([]);

  const toggleWeaponSelectionModal = async () => setShowWeaponSelectionModal(!showWeaponSelectionModal);

  return (
    <MDBModal tabIndex='-1' show={showWeaponSelectionModal} setShow={setShowWeaponSelectionModal}>
      <MDBModalDialog className='modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered'>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Dungeon Mining</MDBModalTitle>
            <MDBBtn
              type='button'
              className='btn-close'
              color='none'
              onClick={toggleWeaponSelectionModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <SelectWeapon
              farmAddress={farmAddress}
              purpose={purpose}
              walletAddress={walletAddress}
              onSelectionChanged={(weapons) => {
                setSelectedWeapons([...weapons]);
              }}
              shown={showWeaponSelectionModal} />
          </MDBModalBody>
          <MDBModalFooter>
            {
              selectedWeapons.length > 0 ?
                <MDBBtn type='button' color='secondary' onClick={() => {
                  toggleWeaponSelectionModal();
                  if (purpose === SelectWeaponPurpose.DEPOSIT) {
                    if (onDepositConfirmed) onDepositConfirmed(selectedWeapons.map(w => w.tokenID));
                  } else {
                    if (onWithdrawConfirmed) onWithdrawConfirmed(selectedWeapons.map(w => w.tokenID));
                  }
                }}>
                  {purpose === SelectWeaponPurpose.DEPOSIT ? 'Stake weapon & Harvest' : 'Withdraw weapon & harvest'}
                </MDBBtn> : null
            }
            <MDBBtn type='button' color='primary' onClick={() => {
              toggleWeaponSelectionModal();
            }}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

/**
 * @param {string} walletAddress
 * @param {function} onSelectionChanged
 * @param {SelectWeaponPurpose} purpose
 */
function SelectWeapon({ farmAddress, walletAddress, onSelectionChanged, purpose, shown }) {
  const [weapons, setWeapons] = useState([]);
  const nftStakingPowerMap = useRef(new Map());
  const [selectedWeapons, setSelectedWeapons] = useState([]);

  const contractWrapper = GetContractWrapper();

  const reload = async () => {
    let tokenIDs = [];

    if (purpose === SelectWeaponPurpose.DEPOSIT) {
      tokenIDs = await contractWrapper.getAllWeaponTokens(walletAddress);
    } else if (purpose === SelectWeaponPurpose.WITHDRAW) {
      tokenIDs = await contractWrapper.getStakingNftIDsOfUser(farmAddress, walletAddress);
    }

    const weapons = [];

    for (const id of tokenIDs) {
      const uri = await contractWrapper.getWeaponTokenURI(id);
      const resp = await axios.get(uri);
      const { data } = resp;
      const weapon = {
        tokenID: id,
        ...data,
      };
      weapons.push(weapon);
    }

    for (const id of tokenIDs) {
      const power = await contractWrapper.getWeaponStakingPower(id);
      nftStakingPowerMap.current.set(id, power);
    }
    setWeapons(weapons);
  };

  const isSelected = (weapon) => {
    for (const sw of selectedWeapons) {
      if (sw.tokenID.toString() === weapon.tokenID.toString()) {
        return true;
      }
    }
    return false;
  }

  const totalSelectedPower = () => {
    let v = ethers.BigNumber.from(0);
    selectedWeapons
      .map(w => nftStakingPowerMap.current.get(w.tokenID))
      .forEach((p) => {
        v = v.add(p);
      });
    return v;
  }

  useEffect(() => {
    if (!walletAddress) return;
    if (!shown) return;
    reload();
    setSelectedWeapons([]);
  }, [shown]);

  return <>
    <div className="row">

      <h3 className="mb-3">{purpose === SelectWeaponPurpose.DEPOSIT ? "Choose weapons to stake" : "Choose weapon to withdraw"}</h3>

      <div className="row">
        {
          weapons.filter((w) => !isSelected(w)).map((weapon) => (
            <div key={weapon.tokenID} className="col-lg-2 col-md-3 my-0">
              <div className="bg-image hover-overlay ripple shadow-1-strong rounded" data-mdb-ripple-color="light">
                <img src={replaceURLToInfuraGateway(weapon.image)} className="w-100" alt="Louvre" />
                <a href="#!" onClick={() => {
                  //if (selectedWeapons.length >= 2) return;
                  selectedWeapons.push(weapon);
                  setSelectedWeapons(selectedWeapons);
                  if (onSelectionChanged) onSelectionChanged(selectedWeapons);
                }}>
                  <div className="mask" style={{ "backgroundColor": "hsla(0, 0%, 98%, 0.2)" }}></div>
                </a>
              </div>
              <p>{"Power: " + formatNumber(nftStakingPowerMap.current.get(weapon.tokenID))}</p>
            </div>
          ))
        }
      </div>

      <hr className="my-4" />

      <div className="col-md-12 my-2">
        <h3 className="mb-4">Chosen weapons : {`${selectedWeapons.length} `}</h3>
        <p>{selectedWeapons.length > 0 ? `Total power: ${formatNumber(totalSelectedPower())}` : "-"}</p>
        <div className="row">
          {
            selectedWeapons.map((weapon) => (
              <div key={weapon.tokenID} className="col-md-3 mb-2">
                <div className="bg-image hover-overlay ripple shadow-1-strong rounded" data-mdb-ripple-color="light">
                  <img src={replaceURLToInfuraGateway(weapon.image)} className="w-100" alt="Louvre" />
                  <a href="#!" onClick={() => {
                    const idx = selectedWeapons.indexOf(weapon);
                    selectedWeapons.splice(idx, 1);
                    setSelectedWeapons(selectedWeapons);
                    if (onSelectionChanged) onSelectionChanged(selectedWeapons);
                  }}>
                    <div className="mask" style={{ "backgroundColor": "hsla(0, 0%, 98%, 0.2)" }}></div>
                  </a>
                </div>
                <p>{"Power: " + formatNumber(nftStakingPowerMap.current.get(weapon.tokenID))}</p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  </>

}

export function MockupDungeonInfoComponent(props) {
  const { imageURL, farmName, miningPeriodBlock, totalMiningReward, miningRate, totalMiningPower, yourMiningPower, weaponReward } = props;
  return (
    <div className="card">
      <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
        <img src={imageURL} className="img-fluid" />
        <a href="#!">
          <div className="mask" style={{ "backgroundColor": `rgba(251, 251, 251, 0.15)` }}></div>
        </a>
      </div>
      <div className="card-body">
        <h5 className="card-title">{farmName}
          <span className="badge rounded-pill bg-warning text-dark" style={{ "marginLeft": "5px" }}>coming soon</span>
        </h5>
        <small>Mining Period: {miningPeriodBlock}</small>
        <hr />
        <p className="card-text">
          <strong>Total Mining Reward: {`${totalMiningReward}`} WCOIN</strong><br />
          Mining Rate: {miningRate * 100} WCOIN/100 Power/Day<br />
          Top 10 Weapons Reward:<br />
          <button type="button" className={`btn ${weaponReward.buttonStyle} my-2`}>
            {weaponReward.info}
          </button>
        </p>
        <p className="card-text">
          Total Mining Power: {totalMiningPower}<br />
          {/* {authenticated && hasApproveNFTToFarm ? <>{`Your Mining Power: ${formatNumber(farmInfo.userMiningPower)}`}<br /></> : null} */}
          {/* Your Mining Rate: {yourMiningPower} WCOIN/Day<br /> */}
          {/* {authenticated && hasApproveNFTToFarm ? <>{`WCOIN Earned: ${formatNumber(farmInfo.pendingReward)}`}</> : null} */}
        </p>
      </div>
    </div>
  );
}
