export const BitkubChainMainnetRPCInfo = { networkID: 96 };
export const BitkubChainTestnetRPCInfo = { networkID: 25925 };


export const MilliSecond = 1;
export const Second = 1000;
export const Minute = 60 * Second;
export const Hour = 60 * Minute;
export const Day = 24 * Hour;

export const MintingQueueState = {
  pending: 1001,
  processingPayment: 2001,
  paymentCompleted: 3001,
  preparingMinting: 4001,
  readyForMinting: 5001,
  confirmForMinting: 5002,
  processingMinting: 6001,
  success: 8001,
  failed: 9001,
};

export function mintingQueueStateToString(state) {
  switch (state) {
    case MintingQueueState.pending: return "Pending";
    case MintingQueueState.processingPayment: return "Processing Payment";
    case MintingQueueState.paymentCompleted: return "Payment completed";
    case MintingQueueState.preparingMinting: return "Preparing Minting";
    case MintingQueueState.readyForMinting: return "Ready for Minting";
    case MintingQueueState.confirmForMinting: return "Confirmed for Minting";
    case MintingQueueState.processingMinting: return "Process Minting";
    case MintingQueueState.success: return "Success";
    case MintingQueueState.failed: return "Failed";
    default: return `Unknown State (${state})`;
  }
}

export const FusionQueueState = {
  pending: 1001,
  processingPayment: 2001,
  paymentCompleted: 3001,
  preparingFusion: 4001,
  readyForFusion: 5001,
  confirmForFusion: 6001,
  processingFusion: 7001,
  success: 8001,
  failed: 9001,
};


export function fusionQueueStateToString(state) {
  switch (state) {
    case FusionQueueState.pending: return "Pending";
    case FusionQueueState.processingPayment: return "Processing Payment";
    case FusionQueueState.paymentCompleted: return "Payment completed";
    case FusionQueueState.preparingFusion: return "Preparing Fusion";
    case FusionQueueState.readyForFusion: return "Ready for Fusion";
    case FusionQueueState.confirmForFusion: return "Confirmed for Fusion";
    case FusionQueueState.processingFusion: return "Process Fusion";
    case FusionQueueState.success: return "Success";
    case FusionQueueState.failed: return "Failed";
  }
}

const {
  REACT_APP_CONTRACT_ADDRESS_CallHelper: CONTRACT_ADDRESS_CallHelper,
  REACT_APP_CONTRACT_ADDRESS_WPMSGoldToken: CONTRACT_ADDRESS_WPMSGoldToken,
  REACT_APP_CONTRACT_ADDRESS_WPMSWcoinToken: CONTRACT_ADDRESS_WPMSWcoinToken,
  REACT_APP_CONTRACT_ADDRESS_WPMSWeaponNFT: CONTRACT_ADDRESS_WPMSWeaponNFT,
  REACT_APP_CONTRACT_ADDRESS_WPMSWeaponMastery: CONTRACT_ADDRESS_WPMSWeaponMastery,
  REACT_APP_CONTRACT_ADDRESS_WPMSVendor: CONTRACT_ADDRESS_WPMSVendor,
  REACT_APP_CONTRACT_ADDRESS_FARM_RobotSwamp: CONTRACT_ADDRESS_FARM_RobotSwamp,
  REACT_APP_CONTRACT_ADDRESS_FARM_SkyFactory: CONTRACT_ADDRESS_FARM_SkyFactory,
  REACT_APP_CONTRACT_ADDRESS_FARM_DeathStar: CONTRACT_ADDRESS_FARM_DeathStar,
  REACT_APP_CONTRACT_ADDRESS_KKUBtoken: CONTRACT_ADDRESS_KKUBtoken,
  REACT_APP_CONTRACT_ADDRESS_KUSDTToken: CONTRACT_ADDRESS_KUSDTToken,
  REACT_APP_CONTRACT_ADDRESS_MorningMoonRouter: CONTRACT_ADDRESS_MorningMoonRouter,
} = process.env;


export const ContractAddressBook = {
  CallHelper: CONTRACT_ADDRESS_CallHelper,
  WPMSGoldToken: CONTRACT_ADDRESS_WPMSGoldToken,
  WPMSWcoinToken: CONTRACT_ADDRESS_WPMSWcoinToken,
  WPMSWeaponNFT: CONTRACT_ADDRESS_WPMSWeaponNFT,
  WPMSWeaponMastery: CONTRACT_ADDRESS_WPMSWeaponMastery,
  WPMSVendor: CONTRACT_ADDRESS_WPMSVendor,

  Farms: {
    RobotSwamp: CONTRACT_ADDRESS_FARM_RobotSwamp,
    SkyFactory: CONTRACT_ADDRESS_FARM_SkyFactory,
    DeathStar: CONTRACT_ADDRESS_FARM_DeathStar
  },

  KKUBtoken: CONTRACT_ADDRESS_KKUBtoken,
  KUSDTToken: CONTRACT_ADDRESS_KUSDTToken,

  MorningMoonRouter: CONTRACT_ADDRESS_MorningMoonRouter,
}
console.log("contract addresses...");
console.log(ContractAddressBook);
console.log("--------------------");


export const WeaponRarity = {
  common: 0,
  great: 1,
  rare: 2,
  epic: 3,
  legendary: 4,
  master: 5,
}

export function weaponRarityToString(rarity) {
  switch (rarity) {
    case WeaponRarity.common: return "Common";
    case WeaponRarity.great: return "Great";
    case WeaponRarity.rare: return "Rare";
    case WeaponRarity.epic: return "Epic";
    case WeaponRarity.legendary: return "Legendary";
    case WeaponRarity.master: return "Master";

    default: return `Unknown rarity (${rarity})`;
  }
}

export function weaponRarityToColor(rarity) {
  switch (rarity) {
    case WeaponRarity.common: return "#ffffff";
    case WeaponRarity.great: return "#66f000";
    case WeaponRarity.rare: return "#00f0ff";
    case WeaponRarity.epic: return "#e467ff";
    case WeaponRarity.legendary: return "#ff9600";
    case WeaponRarity.master: return "#ff008a";

    default: return `Unknown rarity (${rarity})`;
  }
}


export const WeaponType = {
  melee: 0,
  range: 1,
  magicSpell: 2,
  magicMelee: 3,
  magicRange: 4,
}

export function weaponTypeToString(weaponType) {
  switch (weaponType) {
    case WeaponType.melee: return "Melee";
    case WeaponType.range: return "Range";
    case WeaponType.magicSpell: return "Magic Spell";
    case WeaponType.magicMelee: return "Magic Melee";
    case WeaponType.magicRange: return "Magic Range";

    default: return `Unknown WeaponType (${weaponType})`;
  }
}

export function weaponTypeToColor(weaponType) {
  switch (weaponType) {
    case WeaponType.melee: return "#79b15b";
    case WeaponType.range: return "#cc9b4f";
    case WeaponType.magicSpell: return "#49cac8";
    case WeaponType.magicMelee: return "#79b15b";
    case WeaponType.magicRange: return "#cc9b4f";

    default: return `Unknown WeaponType (${weaponType})`;
  }
}


export const ElementalType = {
  Fire: 0,
  Ice: 1,
  Holy: 2,
  Earth: 3,
  Wind: 4,
  Thunder: 5,
  Chaos: 6,
  Dark: 7,
}

export function elementalTypeToString(elementalType) {
  switch (elementalType) {
    case ElementalType.Fire: return "Fire";
    case ElementalType.Ice: return "Ice";
    case ElementalType.Holy: return "Holy";
    case ElementalType.Earth: return "Earth";
    case ElementalType.Wind: return "Wind";
    case ElementalType.Thunder: return "Thunder";
    case ElementalType.Chaos: return "Chaos";
    case ElementalType.Dark: return "Dark";

    default: return `Unknown elementalType (${elementalType})`;
  }
}

export const blockTime = 5 * Second;
export const blocksPerDay = Day / blockTime