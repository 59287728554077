import "./bigImageIntro.styles.css";
import React from "react";
import { AuthContext } from "@ryanar/react-auth-provider";


export function BigImageIntro(props) {
  const { authenticated } = React.useContext(AuthContext);
  return (
    <div id="intro-example" className="p-5 text-center bg-image" style={{ "backgroundImage": "url(/wpms/images/header.jpg)" }}>
      < div className="mask" style={{ "backgroundColor": `rgba(0, 0, 0, 0.7)` }}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="text-white">
            <img src="/wpms/images/logo.png" height="256" alt="Logo" loading="lazy" />
            <h1 className="mb-3">Hello Weaponsmith!</h1>
            <h5 className="mb-4">Welcome to the age of AI.</h5>
            <p>
              Weapon Mastery is AI-generated mining card game where player plays as a Weaponsmith. <br />
              Your goal is to create the best ultimate weapon, using the COMBINE mastery technique which allows you to
              create coutless possibility.
            </p>
            {
              !authenticated ?
                <>
                  <a className="btn btn-outline-light btn-lg m-2" href="./#create" role="button" rel="nofollow" target="_self">Create</a>
                  <a className="btn btn-outline-light btn-lg m-2" href="./#combine" role="button">Combine</a>
                  <a className="btn btn-outline-light btn-lg m-2" href="./#mining" role="button">Mining</a>
                </> :
                <>
                  <a className="btn btn-outline-light btn-lg m-2" href="./#create" role="button" rel="nofollow" target="_self">Create</a>
                  <a className="btn btn-outline-light btn-lg m-2" href="/inventory#inventory" role="button" rel="nofollow" target="_self"><i className="fa fa-wallet me-3"></i>My Inventory</a>
                </>
            }
          </div>
        </div>
      </div >
    </div >
  );
}