import { ethers, utils } from "ethers";
import { BitkubChainMainnetRPCInfo, BitkubChainTestnetRPCInfo } from "../../constants";
import { ChainInfo, currentChain } from "./chain";

export const isConnectToCurrentChain = async (window) => {
  if (!window.ethereum) return false;
  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  console.log("got chainID:", chainId);
  const c = currentChain();
  return chainId === c.chainId;
};

export const isBKCTestnetNetwork = async (window) => {
  if (!window.ethereum) return false;
  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  console.log("got chainID:", chainId);
  return chainId === '0x6545'; // BitkubChainTestnetRPCInfo.networkID;
};

export const isBKCMainnetNetwork = async (window) => {
  if (!window.ethereum) return false;
  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  console.log("got chainID:", chainId);
  return chainId === '0x60'; // BitkubChainMainnetRPCInfo.networkID;
};


/**
 * 
 * @param {*} window 
 * 
 * @param {Object} chain
 * @param {string} chain.chainId - chain ID in hexadecimal
 * @param {string[]} chain.rpcUrls
 * @param {string} chain.chainName
 * @param {string} chain.nativeCurrencychainId
 * @param {string[]} chain.blockExplorerUrls
 * 
 */
const switchChain = async (window, chain) => {
  if (window.ethereum) {
    try {
      // Try to switch to the Mumbai testnet
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chain.chainId }], // Check networks.js for hexadecimal network ids
      });
    } catch (error) {
      // This error code means that the chain we want has not been added to MetaMask
      // In this case we ask the user to add it to their MetaMask
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [chain],
          });
        } catch (error) {
          console.log(error);
        }
      }
      console.log(error);
    }
  } else {
    // If window.ethereum is not found then MetaMask is not installed
    alert('MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html');
  }
}
export const switchToCurrentChain = async (window) => switchChain(window, currentChain());
export const switchToBKCTestnet = async (window) => switchChain(window, ChainInfo.BitkubChainTestnet);
export const switchToBKCMainnet = async (window) => switchChain(window, ChainInfo.BitkubChainMainnet);

const cachedReadOnlyJSONRPCProvider = new Map();

/**
 * 
 * @param {*} window 
 * @param {Object} chain
 * @param {string} chain.chainId - chain ID in hexadecimal
 * @param {string[]} chain.rpcUrls
 * @param {string} chain.chainName
 * @param {string} chain.nativeCurrencychainId
 * @param {string[]} chain.blockExplorerUrls 
 */
export const getReadOnlyJSONRPCProvider = (chain) => {
  if (cachedReadOnlyJSONRPCProvider.has(chain.chainId)) {
    const p = cachedReadOnlyJSONRPCProvider.get(chain.chainId);
    return p;
  }

  console.log("will create json rpc provider with rpc url:", chain.rpcUrls[0]);
  const p = new ethers.providers.JsonRpcProvider(chain.rpcUrls[0]);
  console.log("create json rpc provider with rpc url:", chain.rpcUrls[0], "...done");
  cachedReadOnlyJSONRPCProvider.set(chain.chainId, p);
  return p;
}